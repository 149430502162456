@charset "UTF-8";
/*
Theme Name: HULLO
Theme URI: https://fastsite.pl/
Author: FASTSITE
Author URI: https://fastsite.pl/
Description: Theme for your beloved Client
Version: 1.0
Requires at least: 5.9
Tested up to: 5.9.2
Requires PHP: 7.4
Text Domain: hullo
*/
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/

.blue-btn:disabled {
  cursor: not-allowed;
  background-color: #66c4e9 !important;
}

body {
  background: #eff2f3;
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&family=Varela+Round&display=swap');
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type='search'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */
[hidden] {
  display: none;
}

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-size: 100%;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 2 */
}

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */
a:focus {
  outline: thin dotted;
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0;
}

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */
img {
  border: 0;
  /* 1 */
  -ms-interpolation-mode: bicubic;
  /* 2 */
}

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure {
  margin: 0;
}

/**
 * Correct margin displayed oddly in IE 6/7.
 */
form {
  margin: 0;
}

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  white-space: normal;
  /* 2 */
  *margin-left: -7px;
  /* 3 */
}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
button,
input,
select,
textarea {
  font-size: 100%;
  /* 1 */
  margin: 0;
  /* 2 */
  vertical-align: baseline;
  /* 3 */
  *vertical-align: middle;
  /* 3 */
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
  *overflow: visible;
  /* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type='checkbox'],
input[type='radio'] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
  *height: 13px;
  /* 3 */
  *width: 13px;
  /* 3 */
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type='search'] {
  -webkit-appearance: textfield;
  /* 1 */
  -webkit-box-sizing: content-box;
  /* 2 */
  box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */
}

/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
button,
input,
select,
textarea {
  color: #222;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

img {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/*!
 * Bootstrap Grid v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@-ms-viewport {
  width: device-width;
}
html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 90%;
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 90%;
    max-width: 1140px;
  }
}
@media (min-width: 1440px) {
  .container {
    width: 90%;
    max-width: 1440px;
  }
}
.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  -webkit-box-flex: 1;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  -ms-flex: 0 0 auto;
  -webkit-box-flex: 0;
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  -ms-flex: 0 0 8.333333%;
  -webkit-box-flex: 0;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  -ms-flex: 0 0 16.666667%;
  -webkit-box-flex: 0;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  -ms-flex: 0 0 25%;
  -webkit-box-flex: 0;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -ms-flex: 0 0 33.333333%;
  -webkit-box-flex: 0;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  -ms-flex: 0 0 41.666667%;
  -webkit-box-flex: 0;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  -ms-flex: 0 0 50%;
  -webkit-box-flex: 0;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -ms-flex: 0 0 58.333333%;
  -webkit-box-flex: 0;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  -ms-flex: 0 0 66.666667%;
  -webkit-box-flex: 0;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  -ms-flex: 0 0 75%;
  -webkit-box-flex: 0;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -ms-flex: 0 0 83.333333%;
  -webkit-box-flex: 0;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  -ms-flex: 0 0 91.666667%;
  -webkit-box-flex: 0;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  -ms-flex: 0 0 100%;
  -webkit-box-flex: 0;
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  -ms-flex-order: -1;
  -webkit-box-ordinal-group: 0;
  order: -1;
}

.order-last {
  -ms-flex-order: 13;
  -webkit-box-ordinal-group: 14;
  order: 13;
}

.order-0 {
  -ms-flex-order: 0;
  -webkit-box-ordinal-group: 1;
  order: 0;
}

.order-1 {
  -ms-flex-order: 1;
  -webkit-box-ordinal-group: 2;
  order: 1;
}

.order-2 {
  -ms-flex-order: 2;
  -webkit-box-ordinal-group: 3;
  order: 2;
}

.order-3 {
  -ms-flex-order: 3;
  -webkit-box-ordinal-group: 4;
  order: 3;
}

.order-4 {
  -ms-flex-order: 4;
  -webkit-box-ordinal-group: 5;
  order: 4;
}

.order-5 {
  -ms-flex-order: 5;
  -webkit-box-ordinal-group: 6;
  order: 5;
}

.order-6 {
  -ms-flex-order: 6;
  -webkit-box-ordinal-group: 7;
  order: 6;
}

.order-7 {
  -ms-flex-order: 7;
  -webkit-box-ordinal-group: 8;
  order: 7;
}

.order-8 {
  -ms-flex-order: 8;
  -webkit-box-ordinal-group: 9;
  order: 8;
}

.order-9 {
  -ms-flex-order: 9;
  -webkit-box-ordinal-group: 10;
  order: 9;
}

.order-10 {
  -ms-flex-order: 10;
  -webkit-box-ordinal-group: 11;
  order: 10;
}

.order-11 {
  -ms-flex-order: 11;
  -webkit-box-ordinal-group: 12;
  order: 11;
}

.order-12 {
  -ms-flex-order: 12;
  -webkit-box-ordinal-group: 13;
  order: 12;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-sm-auto {
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    -webkit-box-flex: 0;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    -webkit-box-flex: 0;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-sm-3 {
    -ms-flex: 0 0 25%;
    -webkit-box-flex: 0;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    -webkit-box-flex: 0;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    -webkit-box-flex: 0;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-sm-6 {
    -ms-flex: 0 0 50%;
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    -webkit-box-flex: 0;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    -webkit-box-flex: 0;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-sm-9 {
    -ms-flex: 0 0 75%;
    -webkit-box-flex: 0;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    -webkit-box-flex: 0;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    -webkit-box-flex: 0;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-sm-12 {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    -ms-flex-order: -1;
    -webkit-box-ordinal-group: 0;
    order: -1;
  }

  .order-sm-last {
    -ms-flex-order: 13;
    -webkit-box-ordinal-group: 14;
    order: 13;
  }

  .order-sm-0 {
    -ms-flex-order: 0;
    -webkit-box-ordinal-group: 1;
    order: 0;
  }

  .order-sm-1 {
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
    order: 1;
  }

  .order-sm-2 {
    -ms-flex-order: 2;
    -webkit-box-ordinal-group: 3;
    order: 2;
  }

  .order-sm-3 {
    -ms-flex-order: 3;
    -webkit-box-ordinal-group: 4;
    order: 3;
  }

  .order-sm-4 {
    -ms-flex-order: 4;
    -webkit-box-ordinal-group: 5;
    order: 4;
  }

  .order-sm-5 {
    -ms-flex-order: 5;
    -webkit-box-ordinal-group: 6;
    order: 5;
  }

  .order-sm-6 {
    -ms-flex-order: 6;
    -webkit-box-ordinal-group: 7;
    order: 6;
  }

  .order-sm-7 {
    -ms-flex-order: 7;
    -webkit-box-ordinal-group: 8;
    order: 7;
  }

  .order-sm-8 {
    -ms-flex-order: 8;
    -webkit-box-ordinal-group: 9;
    order: 8;
  }

  .order-sm-9 {
    -ms-flex-order: 9;
    -webkit-box-ordinal-group: 10;
    order: 9;
  }

  .order-sm-10 {
    -ms-flex-order: 10;
    -webkit-box-ordinal-group: 11;
    order: 10;
  }

  .order-sm-11 {
    -ms-flex-order: 11;
    -webkit-box-ordinal-group: 12;
    order: 11;
  }

  .order-sm-12 {
    -ms-flex-order: 12;
    -webkit-box-ordinal-group: 13;
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.333333%;
  }

  .offset-sm-2 {
    margin-left: 16.666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.333333%;
  }

  .offset-sm-5 {
    margin-left: 41.666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.333333%;
  }

  .offset-sm-8 {
    margin-left: 66.666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.333333%;
  }

  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-md-auto {
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    -webkit-box-flex: 0;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    -webkit-box-flex: 0;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-md-3 {
    -ms-flex: 0 0 25%;
    -webkit-box-flex: 0;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    -webkit-box-flex: 0;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    -webkit-box-flex: 0;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-md-6 {
    -ms-flex: 0 0 50%;
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    -webkit-box-flex: 0;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    -webkit-box-flex: 0;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-md-9 {
    -ms-flex: 0 0 75%;
    -webkit-box-flex: 0;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    -webkit-box-flex: 0;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    -webkit-box-flex: 0;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-md-12 {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    -ms-flex-order: -1;
    -webkit-box-ordinal-group: 0;
    order: -1;
  }

  .order-md-last {
    -ms-flex-order: 13;
    -webkit-box-ordinal-group: 14;
    order: 13;
  }

  .order-md-0 {
    -ms-flex-order: 0;
    -webkit-box-ordinal-group: 1;
    order: 0;
  }

  .order-md-1 {
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
    order: 1;
  }

  .order-md-2 {
    -ms-flex-order: 2;
    -webkit-box-ordinal-group: 3;
    order: 2;
  }

  .order-md-3 {
    -ms-flex-order: 3;
    -webkit-box-ordinal-group: 4;
    order: 3;
  }

  .order-md-4 {
    -ms-flex-order: 4;
    -webkit-box-ordinal-group: 5;
    order: 4;
  }

  .order-md-5 {
    -ms-flex-order: 5;
    -webkit-box-ordinal-group: 6;
    order: 5;
  }

  .order-md-6 {
    -ms-flex-order: 6;
    -webkit-box-ordinal-group: 7;
    order: 6;
  }

  .order-md-7 {
    -ms-flex-order: 7;
    -webkit-box-ordinal-group: 8;
    order: 7;
  }

  .order-md-8 {
    -ms-flex-order: 8;
    -webkit-box-ordinal-group: 9;
    order: 8;
  }

  .order-md-9 {
    -ms-flex-order: 9;
    -webkit-box-ordinal-group: 10;
    order: 9;
  }

  .order-md-10 {
    -ms-flex-order: 10;
    -webkit-box-ordinal-group: 11;
    order: 10;
  }

  .order-md-11 {
    -ms-flex-order: 11;
    -webkit-box-ordinal-group: 12;
    order: 11;
  }

  .order-md-12 {
    -ms-flex-order: 12;
    -webkit-box-ordinal-group: 13;
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.333333%;
  }

  .offset-md-2 {
    margin-left: 16.666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.333333%;
  }

  .offset-md-5 {
    margin-left: 41.666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.333333%;
  }

  .offset-md-8 {
    margin-left: 66.666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.333333%;
  }

  .offset-md-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-lg-auto {
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    -webkit-box-flex: 0;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    -webkit-box-flex: 0;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-lg-3 {
    -ms-flex: 0 0 25%;
    -webkit-box-flex: 0;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    -webkit-box-flex: 0;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    -webkit-box-flex: 0;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-lg-6 {
    -ms-flex: 0 0 50%;
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    -webkit-box-flex: 0;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    -webkit-box-flex: 0;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-lg-9 {
    -ms-flex: 0 0 75%;
    -webkit-box-flex: 0;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    -webkit-box-flex: 0;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    -webkit-box-flex: 0;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-lg-12 {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    -ms-flex-order: -1;
    -webkit-box-ordinal-group: 0;
    order: -1;
  }

  .order-lg-last {
    -ms-flex-order: 13;
    -webkit-box-ordinal-group: 14;
    order: 13;
  }

  .order-lg-0 {
    -ms-flex-order: 0;
    -webkit-box-ordinal-group: 1;
    order: 0;
  }

  .order-lg-1 {
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
    order: 1;
  }

  .order-lg-2 {
    -ms-flex-order: 2;
    -webkit-box-ordinal-group: 3;
    order: 2;
  }

  .order-lg-3 {
    -ms-flex-order: 3;
    -webkit-box-ordinal-group: 4;
    order: 3;
  }

  .order-lg-4 {
    -ms-flex-order: 4;
    -webkit-box-ordinal-group: 5;
    order: 4;
  }

  .order-lg-5 {
    -ms-flex-order: 5;
    -webkit-box-ordinal-group: 6;
    order: 5;
  }

  .order-lg-6 {
    -ms-flex-order: 6;
    -webkit-box-ordinal-group: 7;
    order: 6;
  }

  .order-lg-7 {
    -ms-flex-order: 7;
    -webkit-box-ordinal-group: 8;
    order: 7;
  }

  .order-lg-8 {
    -ms-flex-order: 8;
    -webkit-box-ordinal-group: 9;
    order: 8;
  }

  .order-lg-9 {
    -ms-flex-order: 9;
    -webkit-box-ordinal-group: 10;
    order: 9;
  }

  .order-lg-10 {
    -ms-flex-order: 10;
    -webkit-box-ordinal-group: 11;
    order: 10;
  }

  .order-lg-11 {
    -ms-flex-order: 11;
    -webkit-box-ordinal-group: 12;
    order: 11;
  }

  .order-lg-12 {
    -ms-flex-order: 12;
    -webkit-box-ordinal-group: 13;
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.333333%;
  }

  .offset-lg-2 {
    margin-left: 16.666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.333333%;
  }

  .offset-lg-5 {
    margin-left: 41.666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.333333%;
  }

  .offset-lg-8 {
    margin-left: 66.666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.333333%;
  }

  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-xl-auto {
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    -webkit-box-flex: 0;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    -webkit-box-flex: 0;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-xl-3 {
    -ms-flex: 0 0 25%;
    -webkit-box-flex: 0;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    -webkit-box-flex: 0;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    -webkit-box-flex: 0;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-xl-6 {
    -ms-flex: 0 0 50%;
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    -webkit-box-flex: 0;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    -webkit-box-flex: 0;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-xl-9 {
    -ms-flex: 0 0 75%;
    -webkit-box-flex: 0;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    -webkit-box-flex: 0;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    -webkit-box-flex: 0;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-xl-12 {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    -ms-flex-order: -1;
    -webkit-box-ordinal-group: 0;
    order: -1;
  }

  .order-xl-last {
    -ms-flex-order: 13;
    -webkit-box-ordinal-group: 14;
    order: 13;
  }

  .order-xl-0 {
    -ms-flex-order: 0;
    -webkit-box-ordinal-group: 1;
    order: 0;
  }

  .order-xl-1 {
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
    order: 1;
  }

  .order-xl-2 {
    -ms-flex-order: 2;
    -webkit-box-ordinal-group: 3;
    order: 2;
  }

  .order-xl-3 {
    -ms-flex-order: 3;
    -webkit-box-ordinal-group: 4;
    order: 3;
  }

  .order-xl-4 {
    -ms-flex-order: 4;
    -webkit-box-ordinal-group: 5;
    order: 4;
  }

  .order-xl-5 {
    -ms-flex-order: 5;
    -webkit-box-ordinal-group: 6;
    order: 5;
  }

  .order-xl-6 {
    -ms-flex-order: 6;
    -webkit-box-ordinal-group: 7;
    order: 6;
  }

  .order-xl-7 {
    -ms-flex-order: 7;
    -webkit-box-ordinal-group: 8;
    order: 7;
  }

  .order-xl-8 {
    -ms-flex-order: 8;
    -webkit-box-ordinal-group: 9;
    order: 8;
  }

  .order-xl-9 {
    -ms-flex-order: 9;
    -webkit-box-ordinal-group: 10;
    order: 9;
  }

  .order-xl-10 {
    -ms-flex-order: 10;
    -webkit-box-ordinal-group: 11;
    order: 10;
  }

  .order-xl-11 {
    -ms-flex-order: 11;
    -webkit-box-ordinal-group: 12;
    order: 11;
  }

  .order-xl-12 {
    -ms-flex-order: 12;
    -webkit-box-ordinal-group: 13;
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.333333%;
  }

  .offset-xl-2 {
    margin-left: 16.666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.333333%;
  }

  .offset-xl-5 {
    margin-left: 41.666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.333333%;
  }

  .offset-xl-8 {
    margin-left: 66.666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.333333%;
  }

  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}
.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -ms-flexbox !important;
  display: -webkit-box !important;
  display: flex !important;
}

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: -webkit-inline-box !important;
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: -ms-flexbox !important;
    display: -webkit-box !important;
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: -webkit-inline-box !important;
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: -ms-flexbox !important;
    display: -webkit-box !important;
    display: flex !important;
  }

  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: -webkit-inline-box !important;
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: -ms-flexbox !important;
    display: -webkit-box !important;
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: -webkit-inline-box !important;
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: -ms-flexbox !important;
    display: -webkit-box !important;
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: -webkit-inline-box !important;
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: -ms-flexbox !important;
    display: -webkit-box !important;
    display: flex !important;
  }

  .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: -webkit-inline-box !important;
    display: inline-flex !important;
  }
}
.flex-row {
  -ms-flex-direction: row !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  flex-direction: row !important;
}

.flex-column {
  -ms-flex-direction: column !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  flex-direction: column !important;
}

.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
  flex-direction: column-reverse !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  -ms-flex: 1 1 auto !important;
  -webkit-box-flex: 1 !important;
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  -ms-flex-positive: 0 !important;
  -webkit-box-flex: 0 !important;
  flex-grow: 0 !important;
}

.flex-grow-1 {
  -ms-flex-positive: 1 !important;
  -webkit-box-flex: 1 !important;
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}

.justify-content-start {
  -ms-flex-pack: start !important;
  -webkit-box-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -ms-flex-pack: end !important;
  -webkit-box-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -ms-flex-pack: center !important;
  -webkit-box-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -ms-flex-pack: justify !important;
  -webkit-box-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.align-items-start {
  -ms-flex-align: start !important;
  -webkit-box-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -ms-flex-align: end !important;
  -webkit-box-align: end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -ms-flex-align: center !important;
  -webkit-box-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -ms-flex-align: baseline !important;
  -webkit-box-align: baseline !important;
  align-items: baseline !important;
}

.align-items-stretch {
  -ms-flex-align: stretch !important;
  -webkit-box-align: stretch !important;
  align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    -ms-flex-direction: row !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    flex-direction: row !important;
  }

  .flex-sm-column {
    -ms-flex-direction: column !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    -ms-flex: 1 1 auto !important;
    -webkit-box-flex: 1 !important;
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    -ms-flex-positive: 0 !important;
    -webkit-box-flex: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    -ms-flex-positive: 1 !important;
    -webkit-box-flex: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    -ms-flex-pack: start !important;
    -webkit-box-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    -ms-flex-pack: end !important;
    -webkit-box-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    -ms-flex-pack: center !important;
    -webkit-box-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-sm-between {
    -ms-flex-pack: justify !important;
    -webkit-box-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    -ms-flex-align: start !important;
    -webkit-box-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    -ms-flex-align: end !important;
    -webkit-box-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    -ms-flex-align: center !important;
    -webkit-box-align: center !important;
    align-items: center !important;
  }

  .align-items-sm-baseline {
    -ms-flex-align: baseline !important;
    -webkit-box-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    -ms-flex-align: stretch !important;
    -webkit-box-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    -ms-flex-direction: row !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    flex-direction: row !important;
  }

  .flex-md-column {
    -ms-flex-direction: column !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    -ms-flex: 1 1 auto !important;
    -webkit-box-flex: 1 !important;
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    -ms-flex-positive: 0 !important;
    -webkit-box-flex: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    -ms-flex-positive: 1 !important;
    -webkit-box-flex: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    -ms-flex-pack: start !important;
    -webkit-box-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    -ms-flex-pack: end !important;
    -webkit-box-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    -ms-flex-pack: center !important;
    -webkit-box-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-md-between {
    -ms-flex-pack: justify !important;
    -webkit-box-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-md-start {
    -ms-flex-align: start !important;
    -webkit-box-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-md-end {
    -ms-flex-align: end !important;
    -webkit-box-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-md-center {
    -ms-flex-align: center !important;
    -webkit-box-align: center !important;
    align-items: center !important;
  }

  .align-items-md-baseline {
    -ms-flex-align: baseline !important;
    -webkit-box-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    -ms-flex-align: stretch !important;
    -webkit-box-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    -ms-flex-direction: row !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    flex-direction: row !important;
  }

  .flex-lg-column {
    -ms-flex-direction: column !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    -ms-flex: 1 1 auto !important;
    -webkit-box-flex: 1 !important;
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    -ms-flex-positive: 0 !important;
    -webkit-box-flex: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    -ms-flex-positive: 1 !important;
    -webkit-box-flex: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    -ms-flex-pack: start !important;
    -webkit-box-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    -ms-flex-pack: end !important;
    -webkit-box-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    -ms-flex-pack: center !important;
    -webkit-box-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-lg-between {
    -ms-flex-pack: justify !important;
    -webkit-box-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    -ms-flex-align: start !important;
    -webkit-box-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    -ms-flex-align: end !important;
    -webkit-box-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    -ms-flex-align: center !important;
    -webkit-box-align: center !important;
    align-items: center !important;
  }

  .align-items-lg-baseline {
    -ms-flex-align: baseline !important;
    -webkit-box-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    -ms-flex-align: stretch !important;
    -webkit-box-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    -ms-flex-direction: row !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    flex-direction: row !important;
  }

  .flex-xl-column {
    -ms-flex-direction: column !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    -ms-flex: 1 1 auto !important;
    -webkit-box-flex: 1 !important;
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    -ms-flex-positive: 0 !important;
    -webkit-box-flex: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    -ms-flex-positive: 1 !important;
    -webkit-box-flex: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    -ms-flex-pack: start !important;
    -webkit-box-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    -ms-flex-pack: end !important;
    -webkit-box-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    -ms-flex-pack: center !important;
    -webkit-box-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-xl-between {
    -ms-flex-pack: justify !important;
    -webkit-box-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    -ms-flex-align: start !important;
    -webkit-box-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    -ms-flex-align: end !important;
    -webkit-box-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    -ms-flex-align: center !important;
    -webkit-box-align: center !important;
    align-items: center !important;
  }

  .align-items-xl-baseline {
    -ms-flex-align: baseline !important;
    -webkit-box-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    -ms-flex-align: stretch !important;
    -webkit-box-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
/*
COLORS
*/
/*
FONTS
*/
body {
  line-height: 1.25;
  font-family: 'Montserrat', sans-serif;
}

h1 {
  font-family: 'Varela Round', sans-serif;
}

h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', sans-serif;
}

h1 {
  font-size: calc(3.6rem + 6 * (100vw - 32rem) / 160);
}

h2 {
  font-size: calc(3rem + 3 * (100vw - 32rem) / 160);
}

h3 {
  font-size: calc(2.5rem + 2.3 * (100vw - 32rem) / 160);
}

h4 {
  font-size: calc(2.2rem + 1.2 * (100vw - 32rem) / 160);
}

h5 {
  font-size: calc(2rem + 0.4 * (100vw - 32rem) / 160);
}

h6 {
  font-size: calc(1.8rem + 0.2 * (100vw - 32rem) / 160);
}

h1.subpage-header {
  color: white;
  font-size: calc(3rem + 3 * (100vw - 32rem) / 160);
}

.btn {
  font-size: 1.4rem !important;
  font-weight: 700 !important;
  padding: 1.2rem 2.2rem !important;
  border-radius: 1.4rem !important;
  font-family: 'Montserrat', sans-serif !important;
  text-decoration: none !important;
  border: none;
  text-transform: uppercase;
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  letter-spacing: 1.25px;
  line-height: 1.14;
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
}

.blue-btn {
  color: white !important;
  background-color: #009ddb !important;
}
.blue-btn:hover {
  background-color: #66c4e9 !important;
}

.pink-btn {
  color: white !important;
  background-color: #f8aa9e !important;
}
.pink-btn:hover {
  background-color: #f9bbb1 !important;
}

.turquoise-btn {
  color: white !important;
  background-color: #27bdbe !important;
}
.turquoise-btn:hover {
  background-color: #52cacb !important;
}

.white-btn {
  color: #27bdbe;
  background-color: white;
}

.border-btn {
  color: #42515a;
  background-color: white;
  border: 2px solid rgba(66, 81, 90, 0.2);
  padding: 1rem 2.2rem;
}
.border-btn:hover {
  background-color: rgba(66, 81, 90, 0.2) !important;
}

.pink-border-btn {
  color: #42515a;
  background-color: white;
  border: 2px solid #f8aa9e;
}
.pink-border-btn:hover {
  background-color: #f9bbb1 !important;
}

.gray-border-btn {
  color: white;
  border: 2px solid rgba(255, 255, 255, 0.2);
}

.app-btn:before {
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/svg/buttons/app.svg); */
  content: '';
  min-width: 1.8rem;
  width: 1.8rem;
  height: 1.8rem;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 1rem;
}

.plan-btn:before {
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/svg/buttons/plan.svg); */
  content: '';
  min-width: 1.8rem;
  width: 1.8rem;
  height: 1.8rem;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 1rem;
}

.phone-btn:before {
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/svg/buttons/phone.svg); */
  content: '';
  min-width: 1.8rem;
  width: 1.8rem;
  height: 1.8rem;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 1rem;
}

.account-btn {
  width: 4rem;
  height: 4rem;
  background-color: #009ddb;
  border-radius: 1.4rem;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
}
.account-btn:before {
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/svg/buttons/account.svg); */
  content: '';
  width: 1.8rem;
  height: 1.8rem;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.account-btn:hover {
  background-color: #66c4e9;
}

.cart-btn {
  width: 4rem;
  height: 4rem;
  background-color: #42515a;
  border-radius: 1.4rem;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
}
.cart-btn:before {
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/svg/buttons/cart.svg); */
  content: '';
  width: 1.8rem;
  height: 1.8rem;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.cart-btn:hover {
  background-color: #68747b;
}

/*
ADDITIONAL
*/
.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  -o-transition: height 0.35s ease;
  transition: height 0.35s ease;
  -webkit-transition-property: height;
  -o-transition-property: height;
  transition-property: height;
  -webkit-transition-duration: 0.35s;
  -o-transition-duration: 0.35s;
  transition-duration: 0.35s;
  -webkit-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .collapsing {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}
header {
  position: absolute;
  z-index: 1001;
  width: 100%;
}
header .info-row {
  padding: 2rem 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
header .info-row .opening-hours {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}
header .info-row .opening-hours > p {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  letter-spacing: 1.25px;
  font-size: 1.4rem;
  text-transform: uppercase;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: white;
}
header .info-row .opening-hours > p:hover {
  cursor: pointer;
}
header .info-row .opening-hours > p:before {
  content: '';
  width: 2rem;
  height: 2rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 0.5rem;
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/svg/header/clock.svg); */
}
header .info-row .opening-hours:hover .hidden-wrapper {
  opacity: 1;
  visibility: visible;
  z-index: 10;
}
header .info-row .opening-hours .hidden-wrapper {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
  position: absolute;
  background-color: white;
  border-radius: 1.4em;
  -webkit-box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.1);
  padding: 2rem 3rem 1rem 3rem;
  width: 36rem;
  font-weight: 600;
  left: -8rem;
  top: 3.5rem;
}
header .info-row .opening-hours .hidden-wrapper:before {
  content: '';
  background-color: white;
  position: absolute;
  width: 2rem;
  height: 2rem;
  top: -1rem;
  left: 17rem;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
header .info-row .opening-hours .hidden-wrapper h6 {
  color: #42515a;
  margin-bottom: 2rem;
}
header .info-row .opening-hours .hidden-wrapper p {
  font-size: 1.4rem;
  color: #42515a;
  margin-bottom: 1rem;
}
header .info-row .opening-hours .hidden-wrapper > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
header .info-row .opening-hours .hidden-wrapper > div div:first-of-type {
  margin-right: 2rem;
}
header .info-row .opening-hours .hidden-wrapper > div div:first-of-type p {
  color: #009ddb;
}
header .info-row .sizing {
  margin: 0 2rem 0 4rem;
}
header .info-row .sizing p {
  color: #f8f8f8;
  font-weight: 600;
  font-size: 1.6rem;
  cursor: pointer;
}
header .info-row .sizing p span.js-smaller {
  font-size: 1.2rem;
}
header .info-row .sizing p span.js-bigger {
  font-size: 2rem;
}
header .info-row .contrast p {
  color: #f8f8f8;
  font-weight: 700;
  font-size: 1.4rem;
  cursor: pointer;
}
header a {
  outline: none !important;
}
header .top-row {
  background-color: white;
  border-radius: 1.4rem 1.4rem 0 0;
  padding: 2rem 3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
header .top-row .buttons-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
header .top-row .buttons-wrapper a {
  margin-right: 1rem;
}
header .top-row .buttons-wrapper a:last-of-type {
  margin-right: 0;
}
header .top-row .buttons-wrapper .cart-btn span {
  display: none;
}
header .top-row .buttons-wrapper .account-btn span {
  display: none;
}
header .top-row a.account-btn {
  display: none;
}
header .top-row div.account-btn {
  position: relative;
  margin-right: 1rem;
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
}
header .top-row div.account-btn:hover {
  cursor: pointer;
}
header .top-row div.account-btn:hover .hidden-account-buttons {
  visibility: visible;
  opacity: 1;
}
header .top-row .hidden-account-buttons {
  position: absolute;
  border-radius: 1.4rem;
  background-color: white;
  -webkit-box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 23rem;
  top: 5.5rem;
  right: -8rem;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
}
header .top-row .hidden-account-buttons:before {
  content: '';
  background-color: white;
  position: absolute;
  width: 2rem;
  height: 2rem;
  top: -1rem;
  left: 12rem;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
header .top-row .hidden-account-buttons a.blue-btn {
  margin-bottom: 1rem;
  margin-right: 0;
}
header .top-row .hidden-account-buttons a {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
header .top-row .opening-hours-mobile {
  display: none;
}
header .top-row .wrapper-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
header .bottom-row {
  -webkit-box-shadow: 0px 12px 10px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 12px 10px rgba(0, 0, 0, 0.05);
  background-color: #eff2f3;
  border-radius: 0 0 1.4rem 1.4rem;
  padding: 0 5rem;
  position: relative;
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
}
header .bottom-row ul.nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
header .bottom-row ul.nav > li {
  color: #42515a;
  letter-spacing: 0.5px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
header .bottom-row ul.nav > li a {
  padding: 2.5rem 0;
  text-decoration: none;
  color: inherit;
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  z-index: 2;
}
header .bottom-row ul.nav > li a:before {
  content: '';
  width: 2rem;
  height: 2rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 1rem;
  z-index: 1;
}
header .bottom-row ul.nav > li a:after {
  content: '';
  background-color: transparent;
  border-radius: 1rem 1rem 0 0;
  position: absolute;
  width: calc(100% + 6rem);
  left: -3rem;
  bottom: 0;
  height: calc(100% - 1rem);
  z-index: -1;
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
}
@media (max-width: 1200px) {
  header .bottom-row ul.nav > li a:after {
    width: calc(100% + 4rem);
    left: -2rem;
  }
}
header .bottom-row ul.nav > li a:hover:after {
  background-color: white;
}
header .bottom-row ul.nav > li.about a:before {
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/svg/header/o-nas.svg); */
}
header .bottom-row ul.nav > li.news a:before {
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/svg/header/aktualnosci.svg); */
}
header .bottom-row ul.nav > li.services a:before {
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/svg/header/uslugi.svg); */
}
header .bottom-row ul.nav > li.groups a:before {
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/svg/header/dla-grup.svg); */
}
header .bottom-row ul.nav > li.map a:before {
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/svg/header/mapa.svg); */
}
header .bottom-row ul.nav > li.hotel a:before {
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/svg/header/hotel.svg); */
}
header .bottom-row ul.nav > li.menu-item-has-children a:after {
  width: calc(100% + 7rem);
}
header .bottom-row ul.nav > li.menu-item-has-children:after {
  z-index: 2;
  margin-left: 1rem;
  content: '';
  width: 1.5rem;
  height: 1rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/svg/header/arrow.svg); */
}
header .bottom-row ul.nav > li.menu-item-has-children:hover:after {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
header .bottom-row ul.nav > li.menu-item-has-children:hover ul.sub-menu {
  visibility: visible;
  opacity: 1;
}
header .bottom-row ul.nav > li.menu-item-has-children ul.sub-menu {
  -webkit-box-shadow: 0px 12px 10px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 12px 10px rgba(0, 0, 0, 0.05);
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
  visibility: hidden;
  opacity: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: absolute;
  background-color: white;
  width: 100%;
  bottom: -19.6rem;
  left: 0;
  padding: 2rem 5rem;
  border-radius: 0 0 1.4rem 1.4rem;
}
header .bottom-row ul.nav > li.menu-item-has-children ul.sub-menu li {
  margin-right: 1rem;
  width: 20%;
}
header
  .bottom-row
  ul.nav
  > li.menu-item-has-children
  ul.sub-menu
  li:last-of-type {
  margin-right: 0;
}
header .bottom-row ul.nav > li.menu-item-has-children ul.sub-menu li a {
  border: 3px solid transparent;
  border-radius: 1.4rem;
  padding: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
header .bottom-row ul.nav > li.menu-item-has-children ul.sub-menu li a:after {
  display: none;
}
header .bottom-row ul.nav > li.menu-item-has-children ul.sub-menu li a:before {
  content: '';
  margin-bottom: 2rem;
  width: 7.6rem;
  height: 7rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
header .bottom-row ul.nav > li.menu-item-has-children ul.sub-menu li a:hover {
  -webkit-box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.1);
  color: #42515a;
}
header
  .bottom-row
  ul.nav
  > li.menu-item-has-children
  ul.sub-menu
  li.uslugi-rekreacyjne
  a:hover {
  border-color: #27bdbe;
}
header
  .bottom-row
  ul.nav
  > li.menu-item-has-children
  ul.sub-menu
  li.uslugi-rekreacyjne
  a:before {
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/svg/uslugi-rekreac.svg); */
}
header
  .bottom-row
  ul.nav
  > li.menu-item-has-children
  ul.sub-menu
  li.uslugi-sportowe
  a:hover {
  border-color: #ffcb04;
}
header
  .bottom-row
  ul.nav
  > li.menu-item-has-children
  ul.sub-menu
  li.uslugi-sportowe
  a:before {
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/svg/uslugi-sport.svg); */
}
header
  .bottom-row
  ul.nav
  > li.menu-item-has-children
  ul.sub-menu
  li.uslugi-specjalne
  a:hover {
  border-color: #f8aa9e;
}
header
  .bottom-row
  ul.nav
  > li.menu-item-has-children
  ul.sub-menu
  li.uslugi-specjalne
  a:before {
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/svg/uslugi-rehab.svg); */
}
header
  .bottom-row
  ul.nav
  > li.menu-item-has-children
  ul.sub-menu
  li.uslugi-rehabilitacyjne
  a:hover {
  border-color: #da1f3c;
}
header
  .bottom-row
  ul.nav
  > li.menu-item-has-children
  ul.sub-menu
  li.uslugi-rehabilitacyjne
  a:before {
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/svg/uslugi-spec.svg); */
}
header
  .bottom-row
  ul.nav
  > li.menu-item-has-children
  ul.sub-menu
  li.hotel
  a:hover {
  border-color: #009ddb;
}
header
  .bottom-row
  ul.nav
  > li.menu-item-has-children
  ul.sub-menu
  li.hotel
  a:before {
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/svg/uslugi-hotel.svg); */
}
header .menu-mobile-button-wrapper,
header .top-mobile-button-wrapper {
  display: none;
}
@media (max-width: 992px) {
  header .info-row .opening-hours {
    display: none;
  }
  header .top-mobile-button-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  header .top-mobile-button-wrapper button {
    border-radius: 1rem;
    border: 2px solid #eff2f3;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  header .top-mobile-button-wrapper button span:after {
    width: 2rem;
  }
  header .top-mobile-button-wrapper button.is-active span:after {
    width: 3rem;
  }
  header .top-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-transition: all 250ms ease;
    -o-transition: all 250ms ease;
    transition: all 250ms ease;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 1rem 2rem;
  }
  header .top-row .logo-button-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
  }
  header .top-row .mobile-buttons-menu {
    overflow: hidden;
    max-height: 0;
    -webkit-transition: all 250ms ease;
    -o-transition: all 250ms ease;
    transition: all 250ms ease;
    width: 33.3rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  header .top-row .mobile-buttons-menu .wrapper-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: 1rem;
  }
  header .top-row .mobile-buttons-menu.is-buttons-visible {
    max-height: unset;
    margin-top: 1rem;
  }
  header .top-row .mobile-buttons-menu .cart-btn {
    width: unset;
    height: unset;
    font-size: 1.4rem;
    font-weight: 700;
    padding: 1.2rem 2.2rem;
    border-radius: 1.4rem;
    font-family: 'Montserrat', sans-serif;
    text-decoration: none;
    border: none;
    text-transform: uppercase;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    letter-spacing: 1.25px;
    line-height: 1.14;
    -webkit-transition: all 250ms ease;
    -o-transition: all 250ms ease;
    transition: all 250ms ease;
  }
  header .top-row .mobile-buttons-menu .cart-btn:hover {
    -webkit-transform: scale(0.97);
    -ms-transform: scale(0.97);
    transform: scale(0.97);
  }
  header .top-row .mobile-buttons-menu .cart-btn span {
    display: inline;
    color: white;
    margin-left: 1rem;
  }
  header .top-row .mobile-buttons-menu a.account-btn {
    width: unset;
    height: unset;
    font-size: 1.4rem;
    font-weight: 700;
    padding: 1.2rem 2.2rem;
    border-radius: 1.4rem;
    font-family: 'Montserrat', sans-serif;
    text-decoration: none;
    border: none;
    text-transform: uppercase;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    letter-spacing: 1.25px;
    line-height: 1.14;
    -webkit-transition: all 250ms ease;
    -o-transition: all 250ms ease;
    transition: all 250ms ease;
  }
  header .top-row .mobile-buttons-menu a.account-btn:hover {
    -webkit-transform: scale(0.97);
    -ms-transform: scale(0.97);
    transform: scale(0.97);
  }
  header .top-row .mobile-buttons-menu a.account-btn span {
    display: inline;
    color: white;
    margin-left: 1rem;
  }
  header .top-row .mobile-buttons-menu a {
    width: 16rem !important;
    margin-bottom: 1rem;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 1rem 1.5rem;
    height: 4rem;
  }
  header .top-row .mobile-buttons-menu a:nth-child(even) {
    margin-right: 0 !important;
  }
  header .top-row .opening-hours-mobile {
    width: 100%;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  header .top-row .opening-hours-mobile > p {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    letter-spacing: 1.25px;
    font-size: 1.4rem;
    text-transform: uppercase;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #42515a;
  }
  header .top-row .opening-hours-mobile > p:hover {
    cursor: pointer;
  }
  header .top-row .opening-hours-mobile > p:before {
    content: '';
    width: 2rem;
    height: 2rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 0.5rem;
    /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/svg/header/clock-color.svg); */
  }
  header .top-row .opening-hours-mobile .hidden-wrapper {
    max-height: 0;
    overflow: hidden;
    -webkit-transition: all 250ms ease;
    -o-transition: all 250ms ease;
    transition: all 250ms ease;
    background-color: #eff2f3;
    border-radius: 1.4em;
    padding: 0 1.5rem 0 1.5rem;
    font-weight: 600;
    max-height: 0;
  }
  header .top-row .opening-hours-mobile .hidden-wrapper h6 {
    color: #42515a;
    margin-bottom: 2rem;
  }
  header .top-row .opening-hours-mobile .hidden-wrapper p {
    font-size: 1.4rem;
    color: #42515a;
    margin-bottom: 1rem;
  }
  header .top-row .opening-hours-mobile .hidden-wrapper > div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  header
    .top-row
    .opening-hours-mobile
    .hidden-wrapper
    > div
    div:first-of-type {
    margin-right: 2rem;
  }
  header
    .top-row
    .opening-hours-mobile
    .hidden-wrapper
    > div
    div:first-of-type
    p {
    color: #009ddb;
  }
  header .top-row .opening-hours-mobile .hidden-wrapper.show-content {
    max-height: unset;
    margin-top: 1rem;
    padding: 1.5rem 1.5rem 0.5rem 1.5rem;
  }
  header .top-row div.account-btn {
    display: none !important;
  }
  header .top-row a.account-btn {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
    margin-right: 0 !important;
  }
  header .menu-mobile-button-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  header .menu-mobile-button-wrapper button {
    border-radius: 1rem;
    background-color: white;
    font-weight: 600;
    color: #42515a;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  header .menu-mobile-button-wrapper button span {
    margin-right: 1rem;
  }
  header .menu-mobile-button-wrapper button span:after {
    width: 2rem;
  }
  header .menu-mobile-button-wrapper button.is-active span:after {
    width: 3rem;
  }
  header .bottom-row {
    padding: 1rem 2rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-transition: all 250ms ease;
    -o-transition: all 250ms ease;
    transition: all 250ms ease;
  }
  header .bottom-row .menu-wrapper {
    -webkit-transition: all 250ms ease;
    -o-transition: all 250ms ease;
    transition: all 250ms ease;
    max-height: 0;
    overflow: hidden;
    width: 100%;
  }
  header .bottom-row .menu-wrapper.is-visible {
    max-height: unset;
  }
  header .bottom-row ul.nav {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  header .bottom-row ul.nav li a {
    padding: 1rem 0;
  }
  header .bottom-row ul.nav li a:after {
    display: none;
  }
  header .bottom-row ul.nav li.menu-item-has-children {
    position: relative;
    margin-bottom: 0;
  }
  header .bottom-row ul.nav li.menu-item-has-children:after {
    display: none;
  }
  header .bottom-row ul.nav li.menu-item-has-children.visible-margin {
    margin-bottom: 22rem;
  }
  header .bottom-row ul.nav li.menu-item-has-children.visible-margin span {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  header .bottom-row ul.nav li.menu-item-has-children span {
    width: 2rem;
    height: 2rem;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-left: 1rem;
    /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/svg/header/arrow.svg); */
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  header .bottom-row ul.nav li.menu-item-has-children ul.sub-menu {
    bottom: unset;
    top: 4rem;
    position: absolute;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    opacity: 1;
    visibility: visible;
    padding: 0 2rem;
    border-radius: 1.4rem;
    -webkit-transition: all 250ms ease;
    -o-transition: all 250ms ease;
    transition: all 250ms ease;
    max-height: 0;
    overflow: hidden;
  }
  header
    .bottom-row
    ul.nav
    li.menu-item-has-children
    ul.sub-menu.visible-sub-menu {
    max-height: unset;
    padding: 1rem 2rem;
  }
  header .bottom-row ul.nav li.menu-item-has-children ul.sub-menu li {
    margin-right: 0;
    width: 100%;
  }
  header .bottom-row ul.nav li.menu-item-has-children ul.sub-menu li a {
    padding: 1rem 0;
    border: none;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  header .bottom-row ul.nav li.menu-item-has-children ul.sub-menu li a:before {
    display: none;
  }
  header .bottom-row ul.nav li.menu-item-has-children ul.sub-menu li a:hover {
    -webkit-box-shadow: unset;
    box-shadow: unset;
  }
}
@media (max-width: 576px) {
  header .info-row {
    padding: 1rem 0;
  }
  header .top-row {
    padding: 1rem 1.5rem;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  header .bottom-row {
    padding: 1rem 1.5rem;
  }
}
@media (max-width: 380px) {
  header .top-row .mobile-buttons-menu {
    width: 29rem;
  }
  header .top-row .mobile-buttons-menu a {
    font-size: 1.2rem !important;
    padding: 1rem !important;
    width: 14rem !important;
    margin-right: 7px;
    margin-bottom: 7px;
  }
  header .top-row .mobile-buttons-menu a span {
    font-size: inherit;
  }
}

footer {
  background-color: #42515a;
  font-family: 'Montserrat', sans-serif;
  padding: 2rem 0;
}
footer .row:first-of-type .social-media-wrapper {
  padding: 1rem;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 2px solid #71838d;
  border-radius: 3.8rem;
}
footer .row:first-of-type .social-media-wrapper a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
footer .row:first-of-type .social-media-wrapper a:first-of-type {
  margin-right: 1.5rem;
}
footer .row:first-of-type .col-xl-8 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
footer .row:first-of-type .menu-wrapper:first-of-type {
  width: 60%;
}
footer .row:first-of-type .menu-wrapper:first-of-type .wrapper div {
  width: 33.33%;
}
footer .row:first-of-type .menu-wrapper:last-of-type {
  width: 40%;
}
footer .row:first-of-type .menu-wrapper:last-of-type .wrapper div {
  width: 50%;
}
footer .row:first-of-type .menu-wrapper p {
  color: #f8f8f8;
  font-weight: 600;
  margin-bottom: 2.3rem;
}
footer .row:first-of-type .menu-wrapper .wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
footer .row:first-of-type .menu-wrapper .wrapper div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
footer .row:first-of-type .menu-wrapper .wrapper a {
  text-decoration: none;
  color: #8e979c;
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 2rem;
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
}
footer .row:first-of-type .menu-wrapper .wrapper a:hover {
  color: white;
}
footer .row:first-of-type .col-xl-2:last-of-type {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
footer .row:first-of-type .trip-box {
  width: 15rem;
  height: 15.5rem;
  border-radius: 10px;
  border: 2px solid #71838d;
  padding: 1.5rem;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
}
footer .row:first-of-type .trip-box:hover {
  border-color: white;
}
footer .row:first-of-type .trip-box:before {
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/img/footer-btn-tlo.png); */
  content: '';
  width: 80%;
  height: 80%;
  left: 32%;
  top: 41%;
  position: absolute;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
footer .row:first-of-type .trip-box p {
  font-size: 1.4rem;
  color: #f8f8f8;
  font-weight: 600;
}
footer .row:last-of-type .col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
footer .row:last-of-type p,
footer .row:last-of-type a:not(.btn) {
  font-size: 1.2rem;
  color: #f8f8f8;
  font-weight: 600;
  letter-spacing: 0.5px;
}
footer .row:last-of-type a:not(.btn) {
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
}
footer .row:last-of-type a:not(.btn):hover {
  color: #27bdbe;
}
footer .row:last-of-type .buttons-wrapper {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
footer .row:last-of-type .buttons-wrapper a:first-of-type {
  margin-right: 2rem;
}
footer .row:last-of-type .col:last-of-type {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
@media (max-width: 1200px) {
  footer .row:first-of-type .col-xl-8 {
    margin: 2rem 0;
  }
  footer .row:last-of-type .buttons-wrapper {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    margin-bottom: 2rem;
  }
}
@media (max-width: 768px) {
  footer .row:last-of-type .col:last-of-type {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    margin-top: 1rem;
  }
}
@media (max-width: 576px) {
  footer {
    padding: 0;
  }
  footer .row:first-of-type .col-xl-8 {
    display: none;
  }
  footer .row:first-of-type .trip-box {
    width: 100%;
    height: unset;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  footer .row:first-of-type .trip-box svg {
    margin-left: 1rem;
  }
  footer .row:last-of-type .buttons-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  footer .row:last-of-type .buttons-wrapper a:first-of-type {
    margin-bottom: 1rem;
  }
}

.sponsors {
  margin-bottom: -5rem;
  position: relative;
  z-index: 12;
}
.sponsors .wrapper {
  border: 3px solid #eff2f3;
  background-color: white;
  border-radius: 1.4rem;
  padding: 2.5rem 4.5rem;
}
.sponsors .wrapper img {
  max-width: 100%;
}
.sponsors .wrapper p {
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 0.4px;
  color: #8e979c;
  margin-top: 2rem;
}
@media (max-width: 768px) {
  .sponsors .wrapper {
    padding: 2rem;
  }
}

.upper-footer {
  position: relative;
  z-index: 11;
  padding: 8rem 0 4rem 0;
  border-top: 3px solid #eff2f3;
  background-color: white;
}
.upper-footer h6 {
  color: #42515a;
  font-family: 'Varela Round', sans-serif;
}
.upper-footer .info-wrapper p {
  color: #42515a;
  font-family: 'Varela Round', sans-serif;
  margin: 1rem 0;
}
.upper-footer .address-wrapper p {
  font-size: 1.4rem;
  font-weight: 600;
  color: #8e979c;
  margin: 1rem 0;
}
.upper-footer .newsletter-wrapper {
  padding: 0;
}
.upper-footer .newsletter-wrapper form {
  margin-top: 1.5rem;
}
.upper-footer .newsletter-wrapper .wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.upper-footer .newsletter-wrapper input[type='submit'] {
  margin-left: 1rem;
  color: transparent !important;
  width: 4rem;
  height: 4rem;
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/svg/buttons/white-right-arrow.svg); */
  background-position: center;
  background-size: 38%;
  background-repeat: no-repeat;
  border: none !important;
}
.upper-footer .newsletter-wrapper span.wpcf7-spinner {
  position: absolute;
}
.upper-footer .newsletter-wrapper .acceptance-wrapper {
  max-width: 36rem;
}
@media (max-width: 992px) {
  .upper-footer {
    padding: 4rem 0 0;
  }
  .upper-footer .logo-wrapper {
    border-bottom: 3px solid #eff2f3;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
  }
  .upper-footer .newsletter-wrapper {
    border-top: 3px solid #eff2f3;
    padding-top: 2rem;
    margin-top: 2rem;
    border-radius: 0;
  }
}

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  font-size: 62.5%;
}

body {
  overflow: hidden;
  font-size: 1.6rem;
}

section {
  padding: 8rem 0;
  position: relative;
}
@media only screen and (max-width: 768px) {
  section {
    padding: 4.8rem 0;
  }
}

a,
a:focus {
  outline: none !important;
}

*:focus {
  outline: none;
}

div.row {
  padding: 1.6rem 0;
}

.slick-slider {
  -webkit-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  user-select: text !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

section.subpage-header-section {
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/img/header-podstrony.png); */
  padding-top: 21rem;
  padding-bottom: 0;
}
section.subpage-header-section .header-wrapper {
  padding: 2rem 0;
}

.breadcrumbs-wrapper ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.breadcrumbs-wrapper li {
  color: #42515a;
  font-size: 1.2rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.5rem 0;
}
.breadcrumbs-wrapper li:first-of-type:before {
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/svg/header/breadcrumb-home.svg); */
  content: '';
  width: 1.6rem;
  height: 1.6rem;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 0.5rem;
}
.breadcrumbs-wrapper li:after {
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/svg/header/breadcrumb-arrow.svg); */
  content: '';
  width: 1.2rem;
  height: 1.2rem;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 0.5rem;
}
.breadcrumbs-wrapper li:last-of-type::after {
  display: none;
}
.breadcrumbs-wrapper a {
  text-decoration: none;
  color: inherit;
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
}
.breadcrumbs-wrapper a:hover {
  color: #27bdbe;
}

section.second-section {
  padding-top: 0;
}

.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.pagination ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.pagination ul li {
  display: inline-block;
  width: 4.5rem;
  height: 4.5rem;
  margin: 0 0.5rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.pagination ul li a,
.pagination ul li span {
  border-radius: 1.4rem;
  border: 3px solid #8ad8d8;
  width: 100%;
  height: 100%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-decoration: none;
  color: #27bdbe;
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
}
.pagination ul li a.current,
.pagination ul li span.current {
  color: white;
  background-color: #8ad8d8;
}
.pagination ul li a:hover,
.pagination ul li span:hover {
  color: white;
  background-color: #8ad8d8;
}
.pagination ul li a.next,
.pagination ul li a.prev {
  background-color: #27bdbe;
  border-color: #27bdbe;
}
.pagination ul li a.next:before,
.pagination ul li a.prev:before {
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/svg/buttons/white-right-arrow.svg); */
  content: '';
  width: 1.5rem;
  height: 1.2rem;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.pagination ul li a.prev:before {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.formatted-content {
  font-weight: 600;
  line-height: 1.5;
  text-align: justify;
  font-size: 1.4rem;
  color: #68747b;
  font-family: 'Montserrat', sans-serif;
}
.formatted-content strong {
  font-weight: 700;
}
.formatted-content em {
  font-style: italic;
}
.formatted-content a {
  text-decoration: underline;
}
.formatted-content ol {
  margin-bottom: 1rem;
}
.formatted-content ol li {
  list-style: decimal;
  list-style-position: inside;
}
.formatted-content ul {
  margin-bottom: 1rem;
}
.formatted-content ul li {
  list-style: circle;
  list-style-position: outside;
  margin-left: 2rem;
}
.formatted-content p {
  margin-bottom: 1rem;
}
.formatted-content a {
  color: inherit;
}
.formatted-content h1,
.formatted-content h2,
.formatted-content h3,
.formatted-content h4,
.formatted-content h5,
.formatted-content h6 {
  font-family: 'Montserrat', sans-serif;
  text-transform: none;
  font-weight: 600;
  margin-bottom: 1rem;
}
.formatted-content h1 {
  font-size: calc(26px + 8 * (100vw - 320px) / 1600);
}
.formatted-content h2 {
  font-size: calc(24px + 6 * (100vw - 320px) / 1600);
}
.formatted-content h3 {
  font-size: calc(22px + 6 * (100vw - 320px) / 1600);
}
.formatted-content h4 {
  font-size: calc(20px + 4 * (100vw - 320px) / 1600);
}
.formatted-content h5 {
  font-size: calc(18px + 2 * (100vw - 320px) / 1600);
}
.formatted-content h6 {
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
}

section.faq-section {
  position: relative;
  z-index: 10;
}
section.faq-section .row:nth-of-type(2) {
  margin-top: 4rem;
}
section.faq-section .text-wrapper {
  color: #42515a;
  font-weight: 600;
}
section.faq-section .text-wrapper a {
  margin-top: 2rem;
}
section.faq-section .text-wrapper:first-of-type {
  border-bottom: 2px solid #eff2f3;
  padding-bottom: 4rem;
  margin-bottom: 4rem;
  font-weight: 400;
}
section.faq-section .text-wrapper:first-of-type h4 {
  font-family: 'Varela Round', sans-serif;
}
section.faq-section .text-wrapper:first-of-type img {
  margin-left: -2rem;
}
section.faq-section .text-wrapper:last-of-type img {
  margin-bottom: 3rem;
}
section.faq-section:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -18rem;
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/img/podstrony-tlo.png); */
  width: 100%;
  height: 50rem;
  background-size: cover;
}

.accordion-wrapper {
  background-color: #eff2f3;
  padding: 2rem;
  border-radius: 1.4rem;
}
.accordion-wrapper .single-accordion-wrapper {
  border-radius: 1.4rem;
  overflow: hidden;
  margin-bottom: 1rem;
}
.accordion-wrapper button {
  background-color: #eff2f3;
  color: #42515a;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  letter-spacing: 0.5px;
  cursor: pointer;
  padding: 2rem;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  -webkit-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
}
.accordion-wrapper button:after {
  content: '';
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/svg/accordion-arrow.svg); */
  width: 1.8rem;
  height: 1rem;
  min-width: 1.8rem;
  min-height: 1rem;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  background-position: center;
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
  margin-left: 2rem;
}
.accordion-wrapper .active {
  background-color: white;
  color: #27bdbe;
}
.accordion-wrapper .active:after {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.accordion-wrapper .panel {
  background-color: white;
  -webkit-box-shadow: 0px 12px 10px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 12px 10px rgba(0, 0, 0, 0.05);
  color: #71838d;
  font-weight: 600;
  font-size: 1.4rem;
  padding: 0 2rem;
  overflow: hidden;
  max-height: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-transition: max-height 200ms ease-out;
  -o-transition: max-height 200ms ease-out;
  transition: max-height 200ms ease-out;
}
.accordion-wrapper .panel p {
  padding-bottom: 2rem;
}
@media (max-width: 992px) {
  .accordion-wrapper {
    margin-top: 2rem;
  }
}
@media (max-width: 768px) {
  .accordion-wrapper {
    padding: 1rem;
  }
  .accordion-wrapper button {
    padding: 1rem;
  }
  .accordion-wrapper .panel {
    padding: 0 1rem;
  }
  .accordion-wrapper .panel p {
    padding-bottom: 1rem;
  }
}

section.hotel-section {
  background-color: #eff2f3;
}
section.hotel-section .row:nth-of-type(2) {
  margin-top: 4rem;
}
@media (max-width: 576px) {
  section.hotel-section .row:nth-of-type(2) {
    margin-top: 0;
  }
}

.description-slider-wrapper {
  background-color: white;
  border-radius: 1.4rem;
  -webkit-box-shadow: 0px 12px 10px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 12px 10px rgba(0, 0, 0, 0.05);
  padding-bottom: 5rem;
}
.description-slider-wrapper .text-wrapper {
  padding: 5rem 8rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.description-slider-wrapper .text-wrapper h4 {
  color: #27bdbe;
  font-weight: 600;
  padding-right: 5rem;
  min-width: 23rem;
  width: 40%;
}
.description-slider-wrapper .text-wrapper .content {
  width: 60%;
}
.description-slider-wrapper .text-wrapper .content p {
  font-size: 1.4rem;
  font-weight: 600;
  color: #8e979c;
  line-height: 1.5;
}
@media (max-width: 992px) {
  .description-slider-wrapper .text-wrapper {
    padding: 2.5rem 4rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .description-slider-wrapper .text-wrapper h4 {
    width: 100%;
    min-width: unset;
    padding-right: 0;
    margin-bottom: 2rem;
  }
  .description-slider-wrapper .text-wrapper .content {
    width: 100%;
  }
}
@media (max-width: 576px) {
  .description-slider-wrapper {
    padding-bottom: 2rem;
  }
  .description-slider-wrapper .text-wrapper {
    padding: 2rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .description-slider-wrapper .text-wrapper h4 {
    width: 100%;
    min-width: unset;
    padding-right: 0;
    margin-bottom: 2rem;
  }
  .description-slider-wrapper .text-wrapper .content {
    width: 100%;
  }
}

.simple-slider-wrapper .slick-list {
  width: 150%;
  margin-left: -25%;
}
.simple-slider-wrapper .slide {
  padding: 0 1rem;
}
.simple-slider-wrapper .image-wrapper {
  border-radius: 1.4rem;
  overflow: hidden;
  position: relative;
  padding-top: 58%;
}
.simple-slider-wrapper .image-wrapper img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.simple-slider-wrapper .slick-arrow {
  background: #f8aa9e !important;
  width: 7rem;
  height: 7rem;
  z-index: 10;
  border-radius: 100%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.simple-slider-wrapper .slick-arrow:hover,
.simple-slider-wrapper .slick-arrow:focus {
  background: #f8aa9e !important;
}
.simple-slider-wrapper .slick-arrow:before {
  display: none;
}
.simple-slider-wrapper .slick-arrow:after {
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/svg/slider-arrow.svg); */
  content: '';
  width: 2rem;
  height: 1.6rem;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.simple-slider-wrapper .slick-arrow.slick-next:after {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
@media (max-width: 768px) {
  .simple-slider-wrapper .slick-list {
    width: 200%;
    margin-left: -50%;
  }
  .simple-slider-wrapper .slick-arrow {
    width: 5rem;
    height: 5rem;
  }
}
@media (max-width: 576px) {
  .simple-slider-wrapper .slide {
    padding: 0 0.5rem;
  }
  .simple-slider-wrapper .slick-list {
    width: 280%;
    margin-left: -90%;
  }
  .simple-slider-wrapper .slick-arrow.slick-next {
    right: 1rem;
  }
  .simple-slider-wrapper .slick-arrow.slick-prev {
    left: 1rem;
  }
}

section.other-attractions-section .contact-wrapper {
  background: #feeeec;
  border-radius: 1.4rem;
  padding: 3rem;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
section.other-attractions-section .contact-wrapper img {
  width: 5.5rem;
  -o-object-fit: contain;
  object-fit: contain;
  height: 5.5rem;
}
section.other-attractions-section .contact-wrapper h4 {
  font-weight: 600;
  color: #42515a;
  margin: 2rem 0;
}
section.other-attractions-section .contact-wrapper a {
  background-color: transparent;
}
@media (max-width: 768px) {
  section.other-attractions-section .contact-wrapper {
    max-width: unset;
    padding: 2.5rem;
  }
}
section.other-attractions-section .col > h5 {
  color: #42515a;
  font-weight: 600;
}
section.other-attractions-section .box-wrapper {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  margin-top: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
}
section.other-attractions-section .box-wrapper h5 {
  margin-bottom: 1rem;
}
section.other-attractions-section .box-wrapper a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 5rem;
  height: 5rem;
  border-radius: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 2rem;
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
}
section.other-attractions-section .box-wrapper a:after {
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/svg/buttons/white-right-arrow.svg); */
  content: '';
  width: 1.6rem;
  height: 1.2rem;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
section.other-attractions-section .box-wrapper > div {
  width: 27.7rem;
  height: 25.5rem;
  padding: 2.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
section.other-attractions-section .box-wrapper .left-box {
  border-radius: 1.4rem 0 0 1.4rem;
  background-color: #27bdbe;
}
section.other-attractions-section .box-wrapper .left-box a {
  border: 2px solid white;
}
section.other-attractions-section .box-wrapper .left-box a:hover {
  background-color: white;
}
section.other-attractions-section .box-wrapper .left-box a:hover:after {
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/svg/buttons/gray-right-arrow.svg); */
}
section.other-attractions-section .box-wrapper .left-box h5 {
  color: #f8f8f8;
}
section.other-attractions-section .box-wrapper .left-box p {
  color: #a9e5e5;
}
section.other-attractions-section .box-wrapper .right-box {
  border-radius: 0 1.4rem 1.4rem 0;
  border: 2px solid #d9dcde;
  border-left: none;
}
section.other-attractions-section .box-wrapper .right-box a {
  border: 2px solid #b8c2c8;
}
section.other-attractions-section .box-wrapper .right-box a:after {
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/svg/buttons/gray-right-arrow.svg); */
}
section.other-attractions-section .box-wrapper .right-box a:hover {
  background-color: #b8c2c8;
}
section.other-attractions-section .box-wrapper .right-box h5 {
  color: #42515a;
}
section.other-attractions-section .box-wrapper .right-box p {
  color: #8e979c;
}
@media (max-width: 992px) {
  section.other-attractions-section .box-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  section.other-attractions-section .box-wrapper > div {
    width: unset;
    height: unset;
  }
  section.other-attractions-section .box-wrapper .left-box {
    border-radius: 1.4rem 1.4rem 0 0;
  }
  section.other-attractions-section .box-wrapper .right-box {
    border-radius: 0 0 1.4rem 1.4rem;
    border-left: 2px solid #d9dcde;
    border-top: none;
  }
}
@media (max-width: 768px) {
  section.other-attractions-section .box-wrapper {
    margin-bottom: 2rem;
  }
}

section.accommodation-section .header-text-wrapper {
  font-weight: 600;
}
section.accommodation-section .header-text-wrapper h4 {
  color: #42515a;
}
section.accommodation-section .header-text-wrapper p {
  font-size: 1rem;
  color: #8e979c;
  max-width: 35rem;
  margin-top: 2rem;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
section.accommodation-section .header-text-wrapper p svg {
  margin-right: 0.5rem;
}
section.accommodation-section .tabs-wrapper {
  background: #d9dcde;
  border-radius: 48px;
  padding: 1rem;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
section.accommodation-section .tabs-wrapper a {
  padding: 1.5rem 3rem;
  text-decoration: none;
  font-weight: 600;
  color: #42515a;
  outline: none;
}
section.accommodation-section .tabs-wrapper a.active {
  color: #27bdbe;
  background: #ffffff;
  -webkit-box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.1);
  border-radius: 52px;
}

section.groups-section {
  background-color: #eff2f3;
}
section.groups-section .row:nth-of-type(2) {
  margin-top: 4rem;
}
@media (max-width: 576px) {
  section.groups-section {
    padding-bottom: 4.8rem;
  }
  section.groups-section .row:nth-of-type(2) {
    margin-top: 0;
  }
}

section.decorator-planner-section {
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/img/podstrony/dla-grup/img.png); */
  background-size: cover;
  height: 33vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-position: center;
}
section.decorator-planner-section .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
section.decorator-planner-section .col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
section.decorator-planner-section .text-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
section.decorator-planner-section .text-wrapper h3,
section.decorator-planner-section .text-wrapper h2 {
  font-family: 'Varela Round', sans-serif;
  color: white;
  text-align: center;
  max-width: 59rem;
  margin-bottom: 2rem;
}
@media (max-width: 576px) {
  section.decorator-planner-section {
    height: unset;
  }
}

section.contact-section .row:nth-of-type(2) {
  margin-top: 4rem;
}
section.contact-section .info-wrapper h6 {
  font-family: 'Varela Round', sans-serif;
  color: #68747b;
  margin: 1.5rem 0;
}
section.contact-section .info-wrapper p,
section.contact-section .info-wrapper a:not(.btn) {
  font-family: 'Montserrat', sans-serif;
  font-size: calc(18px + 6 * (100vw - 320px) / 1600);
  color: #42515a;
  text-decoration: none;
  font-weight: 600;
  display: block;
  padding-bottom: 0.5rem;
}
section.contact-section .info-wrapper a:not(.btn) {
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
}
section.contact-section .info-wrapper a:not(.btn):hover {
  color: #27bdbe;
}
section.contact-section .info-wrapper a.btn {
  margin-top: 1rem;
}
@media (max-width: 1200px) {
  section.contact-section {
    padding-bottom: 0;
  }
  section.contact-section .info-wrapper {
    margin-bottom: 2rem;
  }
}
@media (max-width: 576px) {
  section.contact-section .row:nth-of-type(2) {
    margin-top: 2rem;
  }
  section.contact-section .input-wrapper h6 {
    margin: 1rem 0;
  }
}

section.questions-form-section {
  padding-bottom: 0;
}
section.questions-form-section .container:first-of-type {
  background: #feeeec;
  border-radius: 1.4rem;
  position: relative;
}
section.questions-form-section .container:first-of-type:after {
  content: '';
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/img/kontakt-bg.png); */
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 50%;
  background-size: cover;
}
section.questions-form-section .container:first-of-type .row {
  padding: 0;
}
section.questions-form-section .container:first-of-type .text-wrapper {
  position: relative;
  z-index: 1;
  background-color: white;
  border-radius: 1.4rem;
  padding: 3rem 6rem;
  -webkit-box-shadow: 0px 12px 10px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 12px 10px rgba(0, 0, 0, 0.05);
  position: relative;
  height: 140%;
  top: -20%;
  width: 80%;
  left: 10%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
section.questions-form-section .container:first-of-type .text-wrapper h6 {
  margin: 1.5rem 0;
  color: #42515a;
  font-weight: 600;
}
@media (max-width: 576px) {
  section.questions-form-section .container:first-of-type .text-wrapper {
    width: 94%;
    left: 3%;
    padding: 2rem;
  }
}
section.questions-form-section .container:last-of-type {
  margin-top: 17rem;
  position: relative;
}
section.questions-form-section .container:last-of-type .col-xl-6:first-of-type {
  padding-right: 0;
}
section.questions-form-section .container:last-of-type:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 45rem;
  background-color: #42515a;
  border-radius: 1.4rem;
}
section.questions-form-section .container:last-of-type .image-wrapper {
  height: 41.8rem;
}
section.questions-form-section .container:last-of-type .image-wrapper img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 1.4rem;
}
section.questions-form-section .container:last-of-type .form-wrapper {
  position: relative;
  top: -10%;
  -webkit-box-shadow: 0px 12px 10px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 12px 10px rgba(0, 0, 0, 0.05);
}
@media (max-width: 992px) {
  section.questions-form-section .container:last-of-type {
    margin-top: 10rem;
  }
  section.questions-form-section
    .container:last-of-type
    .col-xl-6:first-of-type {
    padding-right: 1.5rem;
  }
}
@media (max-width: 576px) {
  section.questions-form-section .container:last-of-type:before {
    height: 30rem;
  }
  section.questions-form-section .container:last-of-type .image-wrapper {
    height: 26.8rem;
  }
}

input,
textarea {
  /* padding: 1rem 1.4rem !important;
  border-radius: 1rem;
  font-family: 'Montserrat', sans-serif;
  border: 2px solid #d9dcde !important;
  color: #b8c2c8; */
}

.form-wrapper {
  border-radius: 1.4rem;
  background-color: white;
  padding: 2rem;
}
.form-wrapper h5 {
  font-family: 'Varela Round', sans-serif;
  color: #27bdbe;
  margin-bottom: 2rem;
}
.form-wrapper .input-wrapper {
  font-family: 'Montserrat', sans-serif;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
}
.form-wrapper .input-wrapper label {
  color: #42515a;
  margin-bottom: 0.5rem;
}
.form-wrapper .input-wrapper input,
.form-wrapper .input-wrapper textarea {
  padding: 1rem 1.4rem;
  border-radius: 1rem;
  font-family: 'Montserrat', sans-serif;
  width: 100%;
  border: 2px solid #d9dcde;
  color: #b8c2c8;
}
.form-wrapper .acceptance-wrapper {
  margin-bottom: 1.5rem;
}
.form-wrapper .acceptance-wrapper span.wpcf7-list-item {
  margin: 0;
}
.form-wrapper .acceptance-wrapper span.wpcf7-list-item-label {
  color: #42515a;
  font-size: 1.2rem;
}
.form-wrapper .acceptance-wrapper span.wpcf7-list-item-label a {
  color: inherit;
}
.form-wrapper .acceptance-wrapper label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.form-wrapper .acceptance-wrapper input {
  padding: 0 !important;
  margin-right: 1rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-width: 1.8rem;
  min-height: 1.8rem;
  width: 1.8rem;
  height: 1.8rem;
  border: 2px solid #d9dcde !important;
  border-radius: 5px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.form-wrapper .acceptance-wrapper input:hover {
  cursor: pointer;
}
.form-wrapper .acceptance-wrapper input:before {
  content: '';
  width: 60%;
  height: 60%;
  border-radius: 2px;
  background-color: transparent;
}
.form-wrapper .acceptance-wrapper input:checked:before {
  background-color: #009ddb;
}
.form-wrapper ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-family: 'Montserrat', sans-serif !important;
  font-size: 1.4rem;
  font-weight: 600;
}
.form-wrapper ::-moz-placeholder {
  /* Firefox 19+ */
  font-family: 'Montserrat', sans-serif !important;
  font-size: 1.4rem;
  font-weight: 600;
}
.form-wrapper :-ms-input-placeholder {
  /* IE 10+ */
  font-family: 'Montserrat', sans-serif !important;
  font-size: 1.4rem;
  font-weight: 600;
}
.form-wrapper :-moz-placeholder {
  /* Firefox 18- */
  font-family: 'Montserrat', sans-serif !important;
  font-size: 1.4rem;
  font-weight: 600;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-family: 'Montserrat', sans-serif !important;
  font-size: 1.4rem;
  font-weight: 600;
}

::-moz-placeholder {
  /* Firefox 19+ */
  font-family: 'Montserrat', sans-serif !important;
  font-size: 1.4rem;
  font-weight: 600;
}

:-ms-input-placeholder {
  /* IE 10+ */
  font-family: 'Montserrat', sans-serif !important;
  font-size: 1.4rem;
  font-weight: 600;
}

:-moz-placeholder {
  /* Firefox 18- */
  font-family: 'Montserrat', sans-serif !important;
  font-size: 1.4rem;
  font-weight: 600;
}

.articles-wrapper article {
  margin-bottom: 4rem;
}
.articles-wrapper article .image-wrapper {
  position: relative;
  padding-top: 61%;
  overflow: hidden;
  border-radius: 1.4rem;
  width: 90%;
}
.articles-wrapper article .image-wrapper a {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.articles-wrapper article .image-wrapper a img {
  width: 100%;
  height: 100%;
}
.articles-wrapper article .image-wrapper .mask {
  position: absolute;
  top: 5%;
  left: -10%;
  width: 120%;
  height: 110%;
}
.articles-wrapper article .text-wrapper {
  position: relative;
  margin-top: -20%;
  width: 83%;
  left: 17%;
  border-radius: 1.4rem;
  -webkit-box-shadow: 0px 12px 10px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 12px 10px rgba(0, 0, 0, 0.05);
  background-color: white;
  padding: 2rem 3rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}
.articles-wrapper article .text-wrapper p.date {
  font-size: 1.2rem;
  color: #27bdbe;
}
.articles-wrapper article .text-wrapper h6 {
  color: #42515a;
  margin: 1.5rem 0;
}
.articles-wrapper article .text-wrapper h6 a {
  color: inherit;
  text-decoration: none;
}
.articles-wrapper article .text-wrapper p.excerpt {
  font-size: 1.4rem;
  color: #8e979c;
  margin-bottom: 2rem;
}
@media (max-width: 576px) {
  .articles-wrapper article {
    margin-bottom: 2rem;
  }
  .articles-wrapper article .text-wrapper {
    padding: 1.5rem;
  }
}
.articles-wrapper .col:nth-of-type(4n-3) article .image-wrapper .mask {
  -webkit-transform: matrix(0.96, 0.21, -0.27, 0.98, 0, 0);
  -ms-transform: matrix(0.96, 0.21, -0.27, 0.98, 0, 0);
  transform: matrix(0.96, 0.21, -0.27, 0.98, 0, 0);
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(7.36%, #009ddb),
    color-stop(88.57%, rgba(0, 157, 219, 0))
  );
  background: -o-linear-gradient(
    bottom,
    #009ddb 7.36%,
    rgba(0, 157, 219, 0) 88.57%
  );
  background: linear-gradient(
    360deg,
    #009ddb 7.36%,
    rgba(0, 157, 219, 0) 88.57%
  );
}
.articles-wrapper .col:nth-of-type(4n-2) article .image-wrapper .mask {
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(7.36%, #27bdbe),
    color-stop(88.57%, rgba(39, 183, 190, 0))
  );
  background: -o-linear-gradient(
    bottom,
    #27bdbe 7.36%,
    rgba(39, 183, 190, 0) 88.57%
  );
  background: linear-gradient(
    360deg,
    #27bdbe 7.36%,
    rgba(39, 183, 190, 0) 88.57%
  );
  -webkit-transform: matrix(0.99, -0.08, 0.11, 1, 0, 0);
  -ms-transform: matrix(0.99, -0.08, 0.11, 1, 0, 0);
  transform: matrix(0.99, -0.08, 0.11, 1, 0, 0);
}
.articles-wrapper .col:nth-of-type(4n-1) article .image-wrapper .mask {
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(7.36%, #f3a2a2),
    color-stop(88.57%, rgba(243, 162, 162, 0))
  );
  background: -o-linear-gradient(
    bottom,
    #f3a2a2 7.36%,
    rgba(243, 162, 162, 0) 88.57%
  );
  background: linear-gradient(
    360deg,
    #f3a2a2 7.36%,
    rgba(243, 162, 162, 0) 88.57%
  );
  -webkit-transform: matrix(0.98, -0.15, 0.2, 0.99, 0, 0);
  -ms-transform: matrix(0.98, -0.15, 0.2, 0.99, 0, 0);
  transform: matrix(0.98, -0.15, 0.2, 0.99, 0, 0);
}
.articles-wrapper .col:nth-of-type(4n) article .image-wrapper .mask {
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(7.36%, #ffcb04),
    color-stop(88.57%, rgba(255, 203, 4, 0))
  );
  background: -o-linear-gradient(
    bottom,
    #ffcb04 7.36%,
    rgba(255, 203, 4, 0) 88.57%
  );
  background: linear-gradient(
    360deg,
    #ffcb04 7.36%,
    rgba(255, 203, 4, 0) 88.57%
  );
  -webkit-transform: matrix(0.99, -0.08, 0.11, 1, 0, 0);
  -ms-transform: matrix(0.99, -0.08, 0.11, 1, 0, 0);
  transform: matrix(0.99, -0.08, 0.11, 1, 0, 0);
}

section.single-post-section {
  background: #eff2f3;
}
section.single-post-section .info-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 1rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 4rem 0 6rem 0;
}
section.single-post-section .info-box .text-wrapper {
  min-height: 30rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 50%;
  background: white;
  -webkit-box-shadow: 0px 12px 10px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 12px 10px rgba(0, 0, 0, 0.05);
  border-radius: 1.4rem 0 0 1.4rem;
  padding: 3rem 5rem;
}
section.single-post-section .info-box .text-wrapper p.date {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.6rem;
  color: #27bdbe;
  font-weight: 600;
  margin-top: 1.5rem;
}
section.single-post-section .info-box .text-wrapper h4 {
  color: #42515a;
  font-weight: 600;
}
section.single-post-section .info-box .text-wrapper .share-wrapper {
  margin-top: 4rem;
}
section.single-post-section .info-box .text-wrapper .share-wrapper p {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.4rem;
  font-weight: 600;
  color: #42515a;
}
section.single-post-section .info-box .text-wrapper .share-wrapper div {
  margin-top: 1rem;
  background-color: #eff2f3;
  border-radius: 4.7rem;
  padding: 0.5rem;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
section.single-post-section .info-box .text-wrapper .share-wrapper div a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 4rem;
  height: 4rem;
  border-radius: 100%;
  background-color: transparent;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
}
section.single-post-section
  .info-box
  .text-wrapper
  .share-wrapper
  div
  a:before {
  content: '';
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/svg/icons/twitter.svg); */
  width: 1.8rem;
  height: 1.6rem;
}
section.single-post-section
  .info-box
  .text-wrapper
  .share-wrapper
  div
  a:nth-of-type(2) {
  margin: 0 0.5rem;
}
section.single-post-section
  .info-box
  .text-wrapper
  .share-wrapper
  div
  a:nth-of-type(2):before {
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/svg/icons/facebook.svg); */
}
section.single-post-section
  .info-box
  .text-wrapper
  .share-wrapper
  div
  a:nth-of-type(3) {
  margin: 0 0.5rem;
}
section.single-post-section
  .info-box
  .text-wrapper
  .share-wrapper
  div
  a:nth-of-type(3):before {
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/svg/icons/linkedin.svg); */
}
section.single-post-section .info-box .text-wrapper .share-wrapper div a:hover {
  background-color: white;
}
section.single-post-section .info-box .image-wrapper {
  width: 50%;
}
section.single-post-section .info-box .image-wrapper .wrapper {
  border-radius: 1.4rem;
  position: relative;
  padding-top: 61%;
  overflow: hidden;
}
section.single-post-section .info-box .image-wrapper .wrapper img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  height: 100%;
}
section.single-post-section .info-box .image-wrapper .wrapper .mask {
  position: absolute;
  top: 5%;
  left: -10%;
  width: 120%;
  height: 110%;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(7.36%, #27bdbe),
    color-stop(88.57%, rgba(39, 183, 190, 0))
  );
  background: -o-linear-gradient(
    bottom,
    #27bdbe 7.36%,
    rgba(39, 183, 190, 0) 88.57%
  );
  background: linear-gradient(
    360deg,
    #27bdbe 7.36%,
    rgba(39, 183, 190, 0) 88.57%
  );
  -webkit-transform: matrix(0.99, -0.07, 0.12, 1, 0, 0);
  -ms-transform: matrix(0.99, -0.07, 0.12, 1, 0, 0);
  transform: matrix(0.99, -0.07, 0.12, 1, 0, 0);
}
@media (max-width: 1200px) {
  section.single-post-section .info-box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    padding: 0;
    margin: 0;
  }
  section.single-post-section .info-box .text-wrapper {
    min-height: unset;
    width: 100%;
    border-radius: 0 0 1.4rem 1.4rem;
  }
  section.single-post-section .info-box .image-wrapper {
    width: 100%;
  }
  section.single-post-section .info-box .image-wrapper .wrapper {
    border-radius: 1.4rem 1.4rem 0 0;
  }
}
@media (max-width: 576px) {
  section.single-post-section .info-box .text-wrapper {
    padding: 1.5rem;
  }
}

section.other-news {
  background: #eff2f3;
  position: relative;
  z-index: 10;
  padding-top: 0;
}
section.other-news:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -20rem;
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/img/podstrony-tlo-1.png); */
  width: 100%;
  height: 50rem;
  background-size: cover;
  background-color: #eff2f3;
}
section.other-news .section-header-wrapper {
  padding: 2rem 0 4rem 0;
  margin: 0;
}
section.other-news .articles-wrapper article {
  margin-bottom: 0;
}
section.other-news .articles-wrapper article .image-wrapper {
  width: 100%;
}
section.other-news .articles-wrapper article .text-wrapper {
  margin-top: 0;
  background: transparent;
  border-radius: 0;
  -webkit-box-shadow: unset;
  box-shadow: unset;
  width: 100%;
  left: 0;
  padding: 2rem 0;
}
@media (max-width: 992px) {
  section.other-news .section-header-wrapper {
    padding: 0;
  }
}

.section-header-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 2rem 0 4rem 0;
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/img/header-bg.png); */
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.section-header-wrapper p {
  color: #8e979c;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  margin-bottom: 1rem;
}
.section-header-wrapper h3 {
  font-family: 'Varela Round', sans-serif;
  color: #42515a;
  text-align: center;
}
@media (max-width: 576px) {
  .section-header-wrapper {
    margin: 0;
  }
}

section.about-us-section .text-wrapper {
  font-weight: 600;
  padding-right: 4rem;
}
section.about-us-section .text-wrapper h5 {
  color: #42515a;
  margin-bottom: 2rem;
}
section.about-us-section .text-wrapper p {
  color: #8e979c;
}
section.about-us-section .image-wrapper img {
  width: 100%;
}
section.about-us-section .box-wrapper {
  font-weight: 600;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
section.about-us-section .box-wrapper div {
  width: 7.6rem;
  height: 7.6rem;
  border-radius: 100%;
  border: 3px solid #eff2f3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
section.about-us-section .box-wrapper div img {
  width: 2.5rem;
  height: 2.5rem;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
}
section.about-us-section .box-wrapper h4 {
  color: #27bdbe;
  margin: 1.5rem 0 0.5rem 0;
}
section.about-us-section .box-wrapper p {
  color: #8e979c;
  text-align: center;
}
section.about-us-section .row:last-of-type {
  margin-top: 6rem;
}
@media (max-width: 992px) {
  section.about-us-section .image-wrapper {
    margin-bottom: 2rem;
  }
  section.about-us-section .text-wrapper {
    padding-right: 0;
  }
}
@media (max-width: 768px) {
  section.about-us-section .box-wrapper {
    margin-bottom: 2rem;
  }
}
@media (max-width: 576px) {
  section.about-us-section .text-wrapper h5 {
    font-size: 1.6rem;
  }
  section.about-us-section .text-wrapper p {
    font-size: 1.4rem;
  }
}

section.gallery-section {
  background: #eff2f3;
}
section.gallery-section:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -18rem;
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/img/podstrony-tlo-1.png); */
  width: 100%;
  height: 50rem;
  background-size: cover;
  background-color: #eff2f3;
}
section.gallery-section .gallery-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-right: -1rem;
  padding: 0 3rem;
}
section.gallery-section .gallery-wrapper .image-wrapper {
  width: 33.33%;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 2rem;
}
@media (max-width: 768px) {
  section.gallery-section .gallery-wrapper .image-wrapper {
    width: 50%;
  }
}
@media (max-width: 576px) {
  section.gallery-section .gallery-wrapper .image-wrapper {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 1rem;
  }
}
section.gallery-section .gallery-wrapper .wrapper {
  padding-top: 69%;
  position: relative;
  border-radius: 1.4rem;
  overflow: hidden;
}
section.gallery-section .gallery-wrapper .wrapper img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -o-object-fit: cover;
  object-fit: cover;
  cursor: pointer;
}
section.gallery-section .overlay-gallery {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(66, 81, 90, 0.8);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
  z-index: 2000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
section.gallery-section .overlay-gallery.is-active {
  opacity: 1;
  visibility: visible;
}
section.gallery-section .overlay-gallery span.close {
  color: white;
  position: absolute;
  top: 2rem;
  right: 2rem;
  cursor: pointer;
  z-index: 2001;
}
section.gallery-section .overlay-gallery span.close svg {
  width: 2rem;
  height: 2rem;
}
section.gallery-section .overlay-gallery .slick-arrow {
  width: 7rem;
  height: 7rem;
  z-index: 10;
  border-radius: 100%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
section.gallery-section .overlay-gallery .slick-arrow:before {
  display: none;
}
section.gallery-section .overlay-gallery .slick-arrow:after {
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/svg/slider-arrow.svg); */
  content: '';
  width: 2rem;
  height: 1.6rem;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
section.gallery-section .overlay-gallery .slick-arrow.slick-next:after {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
section.gallery-section .overlay-gallery .slider {
  width: 80%;
  height: 80%;
}
section.gallery-section .overlay-gallery .slider div {
  height: 100%;
}
section.gallery-section .overlay-gallery .slider .image-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
section.gallery-section .overlay-gallery .slider .image-wrapper img {
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
@media (max-width: 576px) {
  section.gallery-section .overlay-gallery .slider .image-wrapper img {
    height: unset;
    width: 100%;
  }
}
@media (max-width: 992px) {
  section.gallery-section .gallery-wrapper {
    padding: 0;
  }
}

section.single-service-section .row:nth-of-type(2) {
  margin-top: 4rem;
}
section.single-service-section h2 {
  color: #42515a;
  font-family: 'Varela Round', sans-serif;
}
section.single-service-section .text-wrapper {
  font-weight: 600;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
section.single-service-section .text-wrapper h5 {
  color: #42515a;
  margin-bottom: 2rem;
}
section.single-service-section .text-wrapper p {
  color: #8e979c;
  margin-bottom: 2rem;
}
section.single-service-section .image-wrapper {
  margin-top: 4rem;
}
section.single-service-section .image-wrapper img {
  max-width: 70%;
}
@media (max-width: 1200px) {
  section.single-service-section .col-xl-1 {
    display: none;
  }
}
@media (max-width: 992px) {
  section.single-service-section .image-wrapper {
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
}
@media (max-width: 576px) {
  section.single-service-section .text-wrapper h5 {
    font-size: 1.6rem;
  }
  section.single-service-section .text-wrapper p {
    font-size: 1.4rem;
  }
}

.available-attractions {
  margin-top: 4rem;
}
.available-attractions h4 {
  font-family: 'Varela Round', sans-serif;
  color: #42515a;
  margin-bottom: 2rem;
}
.available-attractions .all-attractions-wrapper {
  background: #d4f2f2;
  border-radius: 15px 0px 0px 15px;
  padding: 2rem;
  padding-top: 3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-right: -1rem;
  position: relative;
}
.available-attractions .all-attractions-wrapper:after {
  content: '';
  height: 100%;
  width: 50%;
  position: absolute;
  top: 0;
  right: -50%;
  background-color: inherit;
  z-index: 2;
}
.available-attractions .all-attractions-wrapper:before {
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/img/uslugi-bg.png); */
  content: '';
  position: absolute;
  bottom: 0;
  right: -20%;
  z-index: 3;
  width: 80%;
  height: 80%;
}
.available-attractions .all-attractions-wrapper .single-attraction {
  width: 25%;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 2rem;
  z-index: 4;
}
@media (max-width: 992px) {
  .available-attractions .all-attractions-wrapper .single-attraction {
    width: 33.33%;
  }
}
@media (max-width: 768px) {
  .available-attractions .all-attractions-wrapper .single-attraction {
    width: 50%;
  }
}
@media (max-width: 576px) {
  .available-attractions .all-attractions-wrapper .single-attraction {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 1rem;
  }
}
.available-attractions .all-attractions-wrapper .single-attraction .wrapper {
  background: #ffffff;
  -webkit-box-shadow: 0px 12px 10px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 12px 10px rgba(0, 0, 0, 0.05);
  border-radius: 1.4rem;
  padding: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-decoration: none;
  height: 100%;
}
.available-attractions
  .all-attractions-wrapper
  .single-attraction
  .wrapper
  .image {
  width: 100%;
  border-radius: 1.4rem;
  overflow: hidden;
  padding-top: 69%;
  position: relative;
}
.available-attractions
  .all-attractions-wrapper
  .single-attraction
  .wrapper
  .image
  img {
  position: absolute;
  top: 0;
  left: 0;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.available-attractions
  .all-attractions-wrapper
  .single-attraction
  .wrapper
  .text {
  padding: 2rem 1rem 1rem 1rem;
  font-size: 1.4rem;
  font-weight: 600;
  color: #42515a;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media (max-width: 576px) {
  .available-attractions {
    margin-top: 2rem;
  }
  .available-attractions .all-attractions-wrapper {
    padding: 0.5rem;
    padding-top: 1rem;
  }
  .available-attractions .all-attractions-wrapper span.turquoise-round-arrow {
    display: none;
  }
}

.turquoise-round-arrow {
  width: 3rem;
  min-width: 3rem;
  height: 3rem;
  background-color: #27bdbe;
  border-radius: 100%;
  display: block;
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/svg/buttons/white-right-arrow2.svg); */
  background-position: center;
  background-size: 40%;
  background-repeat: no-repeat;
  border: 2px solid #27bdbe;
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
}
.turquoise-round-arrow:hover {
  background-color: white;
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/svg/buttons/turquoise-right-arrow.svg); */
}

section.other-services {
  background: #eff2f3;
}
section.other-services:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -20rem;
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/img/podstrony-tlo-1.png); */
  width: 100%;
  height: 50rem;
  background-size: cover;
  background-color: #eff2f3;
}
section.other-services .other-services-wrapper {
  background-color: white;
  padding: 1rem;
  border-radius: 1.4rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
section.other-services .other-services-wrapper .service-wrapper {
  width: 20%;
  margin: 0 1rem;
  text-decoration: none;
  display: block;
  border-radius: 1.4rem;
  padding: 2rem;
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
  border: 3px solid transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
section.other-services .other-services-wrapper .service-wrapper:hover {
  -webkit-box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.1);
}
section.other-services
  .other-services-wrapper
  .service-wrapper:nth-of-type(1):hover {
  border-color: #27bdbe;
}
section.other-services
  .other-services-wrapper
  .service-wrapper:nth-of-type(2):hover {
  border-color: #ffcb04;
}
section.other-services
  .other-services-wrapper
  .service-wrapper:nth-of-type(3):hover {
  border-color: #da1f3c;
}
section.other-services
  .other-services-wrapper
  .service-wrapper:nth-of-type(4):hover {
  border-color: #f8aa9e;
}
section.other-services
  .other-services-wrapper
  .service-wrapper:nth-of-type(5):hover {
  border-color: #009ddb;
}
section.other-services
  .other-services-wrapper
  .service-wrapper:nth-of-type(6):hover {
  border-color: #42515a;
}
section.other-services .other-services-wrapper .service-wrapper p {
  font-weight: 600;
  color: #42515a;
  margin-top: 4rem;
}
@media (max-width: 992px) {
  section.other-services .other-services-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  section.other-services .other-services-wrapper .service-wrapper {
    width: 100%;
    margin: 1rem 0;
  }
}
@media (max-width: 576px) {
  section.other-services .other-services-wrapper .service-wrapper {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 1rem;
  }
  section.other-services .other-services-wrapper .service-wrapper p {
    margin-top: 0;
    margin-left: 1rem;
  }
}

section.offer-section .tabs-wrapper .nav {
  background-color: #eff2f3;
  padding: 1rem;
  border-radius: 4.8rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}
section.offer-section .tabs-wrapper .nav button {
  border: none;
  background-color: transparent;
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
  border-radius: 52px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 1.4rem;
  color: #42515a;
  padding: 1.4rem 2.8rem;
  margin-right: 1rem;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
section.offer-section .tabs-wrapper .nav button:before {
  content: '';
  width: 2.5rem;
  height: 1.7rem;
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/svg/buttons/rekreacja.svg); */
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 1rem;
}
section.offer-section .tabs-wrapper .nav button:nth-of-type(2):before {
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/svg/buttons/sport.svg); */
}
section.offer-section .tabs-wrapper .nav button:nth-of-type(3):before {
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/svg/buttons/rehabilitacja.svg); */
}
section.offer-section .tabs-wrapper .nav button:nth-of-type(4):before {
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/svg/buttons/specjalne.svg); */
}
section.offer-section .tabs-wrapper .nav button:last-of-type {
  margin-right: 0;
}
section.offer-section .tabs-wrapper .nav button:last-of-type:before {
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/svg/buttons/hotel.svg); */
}
section.offer-section .tabs-wrapper .nav button:hover {
  -webkit-box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.1);
  background-color: white;
}
section.offer-section .tabs-wrapper .nav button.actual {
  -webkit-box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.1);
  background-color: white;
}
@media (max-width: 992px) {
  section.offer-section .tabs-wrapper .nav {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    border-radius: 3rem;
  }
  section.offer-section .tabs-wrapper .nav button {
    margin-right: 0;
    margin-bottom: 1rem;
  }
  section.offer-section .tabs-wrapper .nav button:last-of-type {
    margin-bottom: 0;
  }
}
section.offer-section .tabs-wrapper .tab .row:nth-of-type(1) {
  margin-top: 4rem;
}
@media (max-width: 1200px) {
  section.offer-section .tabs-wrapper .tab .col-xl-1 {
    display: none;
  }
}
@media (max-width: 576px) {
  section.offer-section .tabs-wrapper .tab .row:nth-of-type(1) {
    margin-top: 2rem;
  }
}
section.offer-section .tabs-wrapper h2 {
  color: #42515a;
  font-family: 'Varela Round', sans-serif;
}
section.offer-section .tabs-wrapper .text-wrapper {
  font-weight: 600;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
section.offer-section .tabs-wrapper .text-wrapper h5 {
  color: #42515a;
  margin-bottom: 2rem;
}
section.offer-section .tabs-wrapper .text-wrapper p {
  color: #8e979c;
  margin-bottom: 2rem;
}
@media (max-width: 576px) {
  section.offer-section .tabs-wrapper .text-wrapper h5 {
    font-size: 1.6rem;
  }
  section.offer-section .tabs-wrapper .text-wrapper p {
    font-size: 1.4rem;
  }
}
section.offer-section .tabs-wrapper .image-wrapper {
  margin-top: 4rem;
}
section.offer-section .tabs-wrapper .image-wrapper img {
  max-width: 100%;
}
@media (max-width: 992px) {
  section.offer-section .tabs-wrapper .image-wrapper {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

section.single-product-section {
  background-color: #eff2f3;
}
section.single-product-section .row:nth-of-type(2) {
  margin-top: 4rem;
}
@media (max-width: 576px) {
  section.single-product-section .row:nth-of-type(2) {
    margin-top: 0;
  }
}

section.amelia-section {
  background: #eff2f3;
  position: relative;
  padding-top: 0;
}
section.amelia-section .section-header-wrapper {
  margin-bottom: 0;
}
section.amelia-section:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/img/podstrony-tlo-1.png); */
  width: 100%;
  height: 50rem;
  background-size: cover;
  background-color: #eff2f3;
}
section.amelia-section button.el-button {
  font-size: 1.4rem !important;
  font-weight: 700 !important;
  padding: 1.2rem 2.2rem !important;
  border-radius: 1.4rem !important;
  font-family: 'Montserrat', sans-serif !important;
  text-decoration: none;
  border: none;
  text-transform: uppercase;
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  letter-spacing: 1.25px;
  line-height: 1.14;
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
  color: white !important;
  background-color: #27bdbe !important;
}
section.amelia-section button.el-button:hover {
  background-color: #52cacb !important;
}
section.amelia-section button.el-button span {
  color: white !important;
}
section.amelia-section .amelia-booking p,
section.amelia-section .amelia-booking div,
section.amelia-section .amelia-booking span {
  color: #42515a !important;
  font-weight: 600;
}
section.amelia-section .amelia-booking svg path {
  color: #27bdbe !important;
}
section.amelia-section .amelia-booking .el-radio-group label.is-active span {
  background-color: #27bdbe !important;
}

section.app-section {
  background-color: white !important;
}
section.app-section::before {
  display: none;
}
section.app-section .app-slider .slide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
section.app-section .app-slider .slide .text-wrapper {
  width: 50%;
  padding: 5rem;
}
section.app-section .app-slider .slide .text-wrapper h4 {
  font-family: 'Varela Round', sans-serif;
  color: #42515a;
  margin-bottom: 0;
}
section.app-section .app-slider .slide .text-wrapper h6 {
  color: #68747b;
  font-weight: 600;
  margin: 2rem 0;
}
section.app-section .app-slider .slide .text-wrapper p {
  color: #68747b;
  font-weight: 600;
  font-size: 1.4rem;
}
section.app-section .app-slider .slide .image-wrapper {
  width: 50%;
}
section.app-section .app-slider button.slick-arrow {
  width: 5.2rem;
  height: 5.2rem;
  border-radius: 100%;
  border: 2px solid #27bdbe;
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
  z-index: 10;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
section.app-section .app-slider button.slick-arrow:before {
  display: none;
}
section.app-section .app-slider button.slick-arrow:after {
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/svg/buttons/turquoise-right-arrow2.svg); */
  content: '';
  width: 2rem;
  height: 1.6rem;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
}
section.app-section .app-slider button.slick-arrow.slick-next {
  background-color: #27bdbe;
}
section.app-section .app-slider button.slick-arrow.slick-next:after {
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/svg/buttons/white-right-arrow.svg); */
}
section.app-section .app-slider button.slick-arrow.slick-next:hover {
  background-color: transparent;
}
section.app-section .app-slider button.slick-arrow.slick-next:hover:after {
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/svg/buttons/turquoise-right-arrow2.svg); */
}
section.app-section .app-slider button.slick-arrow.slick-prev:after {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
section.app-section .app-slider button.slick-arrow.slick-prev:hover {
  background-color: #27bdbe;
}
section.app-section .app-slider button.slick-arrow.slick-prev:hover:after {
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/svg/buttons/white-right-arrow.svg); */
}
section.app-section .app-slider ul.slick-dots {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: unset;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  left: 4rem;
}
section.app-section .app-slider ul.slick-dots li {
  width: unset;
  height: unset;
}
section.app-section .app-slider ul.slick-dots li button {
  background-color: #b8c2c8;
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
}
section.app-section .app-slider ul.slick-dots li button:before {
  display: none;
}
section.app-section .app-slider ul.slick-dots li.slick-active button {
  background-color: #27bdbe;
}
section.app-section .row:nth-of-type(2) {
  background: #eff2f3;
  border-radius: 1.4rem;
  margin: 4rem 0 8rem 0;
}
section.app-section > .row:last-of-type {
  margin-top: 6rem;
}
section.app-section h4 {
  font-family: 'Varela Round', sans-serif;
  color: #42515a;
}
section.app-section .tabs-wrapper {
  margin-top: 2rem;
}
section.app-section .tabs-wrapper .nav {
  background-color: #eff2f3;
  border-radius: 4.8rem;
  padding: 1rem;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
section.app-section .tabs-wrapper .nav button {
  border: none;
  color: #42515a;
  background-color: transparent;
  border-radius: 5.2rem;
  font-size: 1.4rem;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  padding: 1.5rem 3.5rem;
}
section.app-section .tabs-wrapper .nav button:first-of-type {
  margin-right: 1rem;
}
section.app-section .tabs-wrapper .nav button.actual {
  color: #27bdbe;
  background-color: white;
  -webkit-box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.1);
}
section.app-section .tabs-wrapper .tab {
  margin-top: 5rem;
}
section.app-section .tabs-wrapper .col-xl-4 {
  padding-right: 4rem;
}
section.app-section .tabs-wrapper .col-xl-4 span {
  width: 5.6rem;
  height: 5.6rem;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 2.2rem;
  font-family: 'Varela Round', sans-serif;
  color: #27bdbe;
  background-color: #d4f2f2;
  border-radius: 1rem;
}
section.app-section .tabs-wrapper .col-xl-4 h6 {
  font-family: 'Varela Round', sans-serif;
  color: #42515a;
  margin: 2rem 0 1rem 0;
}
section.app-section .tabs-wrapper .col-xl-4 p {
  font-size: 1.2rem;
  color: #8e979c;
  font-weight: 600;
}
@media (max-width: 992px) {
  section.app-section .app-slider .slide {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  section.app-section .app-slider .slide .text-wrapper {
    padding: 2rem;
    width: 100%;
  }
  section.app-section .app-slider .slide .image-wrapper {
    width: 100%;
  }
}
@media (max-width: 768px) {
  section.app-section .tabs-wrapper .tab {
    margin-top: 2rem;
  }
  section.app-section .tabs-wrapper .col-xl-4 {
    padding-right: 1.5rem;
    margin-bottom: 2rem;
  }
}

section.map-section h4 {
  font-weight: 600;
  color: #42515a;
}
section.map-section .select-wrapper {
  margin-top: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-bottom: 2rem;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  /* Style the arrow inside the select element: */
  /* Point the arrow upwards when the select box is open (active): */
  /* style the items (options), including the selected item: */
  /* Style items (options): */
  /* Hide the items when the select box is closed: */
}
section.map-section .select-wrapper .custom-select {
  position: relative;
  width: 300px;
  margin-bottom: 0.5rem;
}
section.map-section .select-wrapper .custom-select p {
  font-weight: 600;
  color: #42515a;
  margin-bottom: 1rem;
}
section.map-section .select-wrapper .custom-select.budynek {
  margin-right: 2rem;
}
section.map-section .select-wrapper .custom-select select {
  display: none;
  /*hide original SELECT element: */
}
section.map-section .select-wrapper .select-selected {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
  text-align: center;
  border: 2px solid #d9dcde;
  background-color: white;
  border-radius: 1rem;
  width: 300px;
}
section.map-section .select-wrapper .select-selected.select-arrow-active {
  border-bottom: unset;
  border-radius: 1rem 1rem 0 0;
}
section.map-section
  .select-wrapper
  .select-selected.select-arrow-active
  ~ .select-items {
  border-radius: 0 0 1rem 1rem;
}
section.map-section .select-wrapper .select-selected:after {
  content: '';
  margin-left: 1rem;
  width: 1.5rem;
  height: 1.5rem;
  min-height: 1.5rem;
  min-width: 1.5rem;
  position: relative;
  z-index: 1;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/svg/header/arrow.svg); */
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
  background-color: unset;
}
section.map-section .select-wrapper .select-selected.select-arrow-active:after {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
section.map-section .select-wrapper .select-items div,
section.map-section .select-wrapper .select-selected {
  color: #42515a;
  font-weight: 600;
  padding: 1rem 1.5rem !important;
  cursor: pointer;
}
section.map-section .select-wrapper .select-items {
  position: absolute;
  background-color: white;
  border: 2px solid #d9dcde;
  border-radius: 1rem;
  top: calc(100% - 2px);
  left: 0;
  right: 0;
  z-index: 99;
  width: 300px;
}
section.map-section .select-wrapper .select-hide {
  display: none;
}
section.map-section .select-wrapper .select-items div:hover,
section.map-section .select-wrapper .same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}
section.map-section .select-wrapper .hard-packages .package-box {
  height: unset !important;
}
section.map-section .tooltip {
  opacity: 0;
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
}
section.map-section .building {
  cursor: pointer;
}
section.map-section .legend {
  cursor: pointer;
}
section.map-section .legend rect {
  opacity: 0;
}
section.map-section .legend:hover rect {
  opacity: 1;
}
section.map-section .legend-wrapper svg {
  width: 100%;
  height: auto;
}
section.map-section .map-wrapper svg {
  width: 100%;
  height: auto;
}
section.map-section .map-col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #71838d;
  padding: 2rem 1rem;
  border-radius: 0.875rem;
}
@media (max-width: 992px) {
  section.map-section .map-col {
    display: none;
  }
}
section.map-section .legend-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: start;
  align-self: flex-start;
  margin-bottom: 2rem;
}
@media (max-width: 992px) {
  section.map-section .legend-wrapper {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
section.map-section .legend-wrapper svg {
  width: 100%;
  height: auto;
}
section.map-section .legend-wrapper svg .legend-area {
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
}
section.map-section .legend-wrapper svg .legend-area:hover {
  cursor: pointer;
  fill: #deb200;
}
section.map-section
  .legend-wrapper
  svg.pierwsze-pietro-hullo-sport-legend
  .cls-1 {
  fill: #fc0;
}
section.map-section
  .legend-wrapper
  svg.pierwsze-pietro-hullo-sport-legend
  .cls-2 {
  fill: #fff;
}
section.map-section
  .legend-wrapper
  svg.pierwsze-pietro-hullo-sport-legend
  .cls-3 {
  fill: #3c3c3b;
}
section.map-section
  .legend-wrapper
  svg.pierwsze-pietro-hullo-sport-legend
  .cls-4 {
  fill: #3e3e3c;
}
section.map-section .parter-hullo-fun .map-svg-wrapper,
section.map-section .pierwsze-pietro-hullo-fun .map-svg-wrapper,
section.map-section .pierwsze-pietro-hullo-sport .map-svg-wrapper,
section.map-section .parter-hullo-sport .map-svg-wrapper {
  width: 850px !important;
  height: unset !important;
}
section.map-section .parter-hullo-fun .map-svg-wrapper svg,
section.map-section .pierwsze-pietro-hullo-fun .map-svg-wrapper svg,
section.map-section .pierwsze-pietro-hullo-sport .map-svg-wrapper svg,
section.map-section .parter-hullo-sport .map-svg-wrapper svg {
  width: 100% !important;
  height: auto !important;
}
section.map-section .map-svg-wrapper {
  position: relative;
}
section.map-section .map-svg-wrapper svg.parter-hullo-fun-mapa .cls-1 {
  fill: none;
}
section.map-section .map-svg-wrapper svg.parter-hullo-fun-mapa .cls-2 {
  fill: #95c122;
}
section.map-section .map-svg-wrapper svg.parter-hullo-fun-mapa .cls-3 {
  fill: #fc0;
}
section.map-section .map-svg-wrapper svg.parter-hullo-fun-mapa .cls-4 {
  fill: #c7c6c6;
}
section.map-section .map-svg-wrapper svg.parter-hullo-fun-mapa .cls-5 {
  fill: #fff;
}
section.map-section .map-svg-wrapper svg.parter-hullo-fun-mapa .cls-6 {
  -webkit-clip-path: url(#clip-path);
  clip-path: url(#clip-path);
}
section.map-section .map-svg-wrapper svg.parter-hullo-fun-mapa .cls-7 {
  -webkit-clip-path: url(#clip-path-2);
  clip-path: url(#clip-path-2);
}
section.map-section .map-svg-wrapper svg.parter-hullo-fun-mapa .cls-8 {
  fill: #3c3c3b;
}
section.map-section .map-svg-wrapper svg.pierwsze-pietro-hullo-fun-mapa .cls-1 {
  fill: none;
}
section.map-section .map-svg-wrapper svg.pierwsze-pietro-hullo-fun-mapa .cls-2 {
  fill: #fc0;
}
section.map-section .map-svg-wrapper svg.pierwsze-pietro-hullo-fun-mapa .cls-3 {
  fill: #c7c6c6;
}
section.map-section .map-svg-wrapper svg.pierwsze-pietro-hullo-fun-mapa .cls-4 {
  fill: #3c3c3b;
}
section.map-section .map-svg-wrapper svg.pierwsze-pietro-hullo-fun-mapa .cls-5 {
  fill: #fff;
}
section.map-section .map-svg-wrapper svg.pierwsze-pietro-hullo-fun-mapa .cls-6 {
  -webkit-clip-path: url(#clip-path);
  clip-path: url(#clip-path);
}
section.map-section .map-svg-wrapper svg.pierwsze-pietro-hullo-fun-mapa .cls-7 {
  -webkit-clip-path: url(#clip-path-2);
  clip-path: url(#clip-path-2);
}
section.map-section .map-svg-wrapper svg.parter-hullo-sport-mapa .cls-1 {
  fill: #fc0;
}
section.map-section .map-svg-wrapper svg.parter-hullo-sport-mapa .cls-2 {
  fill: #c7c6c6;
}
section.map-section .map-svg-wrapper svg.parter-hullo-sport-mapa .cls-3 {
  fill: #fff;
}
section.map-section .map-svg-wrapper svg.parter-hullo-sport-mapa .cls-4 {
  fill: #d8b135;
}
section.map-section .map-svg-wrapper svg.parter-hullo-sport-mapa .cls-5 {
  fill: #c5a547;
}
section.map-section .map-svg-wrapper svg.parter-hullo-sport-mapa .cls-6 {
  fill: #bda04e;
}
section.map-section .map-svg-wrapper svg.parter-hullo-sport-mapa .cls-7 {
  fill: #3c3c3b;
}
section.map-section
  .map-svg-wrapper
  svg.pierwsze-pietro-hullo-sport-mapa
  .cls-1 {
  fill: none;
}
section.map-section
  .map-svg-wrapper
  svg.pierwsze-pietro-hullo-sport-mapa
  .cls-2 {
  -webkit-clip-path: url(#clip-path);
  clip-path: url(#clip-path);
}
section.map-section
  .map-svg-wrapper
  svg.pierwsze-pietro-hullo-sport-mapa
  .cls-3 {
  fill: #fc0;
}
section.map-section
  .map-svg-wrapper
  svg.pierwsze-pietro-hullo-sport-mapa
  .cls-4 {
  fill: #c7c6c6;
}
section.map-section
  .map-svg-wrapper
  svg.pierwsze-pietro-hullo-sport-mapa
  .cls-5 {
  fill: #fff;
}
section.map-section
  .map-svg-wrapper
  svg.pierwsze-pietro-hullo-sport-mapa
  .cls-6 {
  fill: #d8b135;
}
section.map-section
  .map-svg-wrapper
  svg.pierwsze-pietro-hullo-sport-mapa
  .cls-7 {
  fill: #c5a547;
}
section.map-section
  .map-svg-wrapper
  svg.pierwsze-pietro-hullo-sport-mapa
  .cls-8 {
  fill: #bda04e;
}
section.map-section
  .map-svg-wrapper
  svg.pierwsze-pietro-hullo-sport-mapa
  .cls-9 {
  -webkit-clip-path: url(#clip-path-3);
  clip-path: url(#clip-path-3);
}
section.map-section
  .map-svg-wrapper
  svg.pierwsze-pietro-hullo-sport-mapa
  .cls-10 {
  -webkit-clip-path: url(#clip-path-4);
  clip-path: url(#clip-path-4);
}
section.map-section
  .map-svg-wrapper
  svg.pierwsze-pietro-hullo-sport-mapa
  .cls-11 {
  fill: #3c3c3b;
}
section.map-section .map-svg-wrapper svg .area {
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
}
section.map-section .map-svg-wrapper svg .area:hover {
  cursor: pointer;
  fill: #009ddb;
}
section.map-section .map-svg-wrapper svg .area.filled {
  fill: #009ddb;
}
section.map-section .tooltip {
  background: white;
  border-radius: 0.875rem;
  padding: 1rem;
  width: 250px;
  position: absolute;
  opacity: 0;
  display: block;
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
}
section.map-section .tooltip.visible {
  opacity: 1;
}
section.map-section .tooltip:after {
  content: '';
  position: absolute;
  left: calc(50% - 0.75rem);
  bottom: -1rem;
  width: 1.5rem;
  height: 1.5rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/svg/tooltip.svg); */
}
section.map-section .tooltip p {
  text-align: center;
}
section.map-section .tooltip p:first-of-type {
  color: #009ddb;
  margin-bottom: 0.5rem;
}
section.map-section #sala-zabaw-tooltip {
  left: 57% !important;
  top: -1rem !important;
}
section.map-section #sala-sensoryczna-tooltip {
  left: 59% !important;
  top: 263px !important;
}

section.map-svg {
  margin: 5rem 0;
}
@media (max-width: 992px) {
  section.map-svg {
    margin-bottom: 5rem;
  }
}
section.map-svg .widok-ogolny .tooltip {
  opacity: 0;
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
}
section.map-svg .widok-ogolny .building {
  cursor: pointer;
}
section.map-svg .widok-ogolny .legend {
  cursor: pointer;
}
section.map-svg .widok-ogolny .legend rect {
  opacity: 0;
}
section.map-svg .widok-ogolny .legend:hover rect {
  opacity: 1;
}
section.map-svg .widok-ogolny .legend-wrapper svg {
  width: 100%;
  height: auto;
}
section.map-svg .widok-ogolny .map-wrapper svg {
  width: 100%;
  height: auto;
}
section.map-svg .map-row {
  display: none;
}
section.map-svg .widok-ogolny {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
section.map-svg .map-col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #71838d;
  padding: 2rem 1rem;
  border-radius: 0.875rem;
}
@media (max-width: 992px) {
  section.map-svg .map-col {
    display: none;
  }
}
section.map-svg .select-col h4 {
  font-weight: 600;
  color: #42515a;
}
section.map-svg .select-wrapper {
  margin-top: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-bottom: 2rem;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  /* Style the arrow inside the select element: */
  /* Point the arrow upwards when the select box is open (active): */
  /* style the items (options), including the selected item: */
  /* Style items (options): */
  /* Hide the items when the select box is closed: */
}
section.map-svg .select-wrapper .custom-select {
  position: relative;
  width: 300px;
  margin-bottom: 0.5rem;
}
section.map-svg .select-wrapper .custom-select p {
  font-weight: 600;
  color: #42515a;
  margin-bottom: 1rem;
}
section.map-svg .select-wrapper .custom-select.budynek {
  margin-right: 2rem;
}
section.map-svg .select-wrapper .custom-select select {
  display: none;
  /*hide original SELECT element: */
}
section.map-svg .select-wrapper .select-selected {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
  text-align: center;
  border: 2px solid #d9dcde;
  background-color: white;
  border-radius: 1rem;
  width: 300px;
}
section.map-svg .select-wrapper .select-selected.select-arrow-active {
  border-bottom: unset;
  border-radius: 1rem 1rem 0 0;
}
section.map-svg
  .select-wrapper
  .select-selected.select-arrow-active
  ~ .select-items {
  border-radius: 0 0 1rem 1rem;
}
section.map-svg .select-wrapper .select-selected:after {
  content: '';
  margin-left: 1rem;
  width: 1.5rem;
  height: 1.5rem;
  min-height: 1.5rem;
  min-width: 1.5rem;
  position: relative;
  z-index: 1;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/svg/header/arrow.svg); */
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
  background-color: unset;
}
section.map-svg .select-wrapper .select-selected.select-arrow-active:after {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
section.map-svg .select-wrapper .select-items div,
section.map-svg .select-wrapper .select-selected {
  color: #42515a;
  font-weight: 600;
  padding: 1rem 1.5rem !important;
  cursor: pointer;
}
section.map-svg .select-wrapper .select-items {
  position: absolute;
  background-color: white;
  border: 2px solid #d9dcde;
  border-radius: 1rem;
  top: calc(100% - 2px);
  left: 0;
  right: 0;
  z-index: 99;
  width: 300px;
}
section.map-svg .select-wrapper .select-hide {
  display: none;
}
section.map-svg .select-wrapper .select-items div:hover,
section.map-svg .select-wrapper .same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}
section.map-svg .select-wrapper .hard-packages .package-box {
  height: unset !important;
}
section.map-svg .legend-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: start;
  align-self: flex-start;
  margin-bottom: 2rem;
}
@media (max-width: 992px) {
  section.map-svg .legend-wrapper {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
section.map-svg .legend-wrapper svg {
  width: 100%;
  height: auto;
}
section.map-svg .legend-wrapper svg .legend-area {
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
}
section.map-svg .legend-wrapper svg .legend-area:hover {
  cursor: pointer;
  fill: #deb200;
}
section.map-svg .legend-wrapper svg.pierwsze-pietro-legend-2 .cls-1 {
  fill: #fc0;
}
section.map-svg .legend-wrapper svg.pierwsze-pietro-legend-2 .cls-2 {
  fill: #fff;
}
section.map-svg .legend-wrapper svg.pierwsze-pietro-legend-2 .cls-3 {
  fill: #3c3c3b;
}
section.map-svg .legend-wrapper svg.pierwsze-pietro-legend-2 .cls-4 {
  fill: #3e3e3c;
}
section.map-svg .map-svg-wrapper {
  position: relative;
}
section.map-svg .map-svg-wrapper svg.parter-mapa-1 .cls-1 {
  fill: none;
}
section.map-svg .map-svg-wrapper svg.parter-mapa-1 .cls-2 {
  fill: #95c122;
}
section.map-svg .map-svg-wrapper svg.parter-mapa-1 .cls-3 {
  fill: #fc0;
}
section.map-svg .map-svg-wrapper svg.parter-mapa-1 .cls-4 {
  fill: #c7c6c6;
}
section.map-svg .map-svg-wrapper svg.parter-mapa-1 .cls-5 {
  fill: #fff;
}
section.map-svg .map-svg-wrapper svg.parter-mapa-1 .cls-6 {
  -webkit-clip-path: url(#clip-path);
  clip-path: url(#clip-path);
}
section.map-svg .map-svg-wrapper svg.parter-mapa-1 .cls-7 {
  -webkit-clip-path: url(#clip-path-2);
  clip-path: url(#clip-path-2);
}
section.map-svg .map-svg-wrapper svg.parter-mapa-1 .cls-8 {
  fill: #3c3c3b;
}
section.map-svg .map-svg-wrapper svg.pierwsze-pietro-mapa-1 .cls-1 {
  fill: none;
}
section.map-svg .map-svg-wrapper svg.pierwsze-pietro-mapa-1 .cls-2 {
  fill: #fc0;
}
section.map-svg .map-svg-wrapper svg.pierwsze-pietro-mapa-1 .cls-3 {
  fill: #c7c6c6;
}
section.map-svg .map-svg-wrapper svg.pierwsze-pietro-mapa-1 .cls-4 {
  fill: #3c3c3b;
}
section.map-svg .map-svg-wrapper svg.pierwsze-pietro-mapa-1 .cls-5 {
  fill: #fff;
}
section.map-svg .map-svg-wrapper svg.pierwsze-pietro-mapa-1 .cls-6 {
  -webkit-clip-path: url(#clip-path);
  clip-path: url(#clip-path);
}
section.map-svg .map-svg-wrapper svg.pierwsze-pietro-mapa-1 .cls-7 {
  -webkit-clip-path: url(#clip-path-2);
  clip-path: url(#clip-path-2);
}
section.map-svg .map-svg-wrapper svg.parter-mapa-2 .cls-1 {
  fill: #fc0;
}
section.map-svg .map-svg-wrapper svg.parter-mapa-2 .cls-2 {
  fill: #c7c6c6;
}
section.map-svg .map-svg-wrapper svg.parter-mapa-2 .cls-3 {
  fill: #fff;
}
section.map-svg .map-svg-wrapper svg.parter-mapa-2 .cls-4 {
  fill: #d8b135;
}
section.map-svg .map-svg-wrapper svg.parter-mapa-2 .cls-5 {
  fill: #c5a547;
}
section.map-svg .map-svg-wrapper svg.parter-mapa-2 .cls-6 {
  fill: #bda04e;
}
section.map-svg .map-svg-wrapper svg.parter-mapa-2 .cls-7 {
  fill: #3c3c3b;
}
section.map-svg .map-svg-wrapper svg.pierwsze-pietro-mapa-2 .cls-1 {
  fill: none;
}
section.map-svg .map-svg-wrapper svg.pierwsze-pietro-mapa-2 .cls-2 {
  -webkit-clip-path: url(#clip-path);
  clip-path: url(#clip-path);
}
section.map-svg .map-svg-wrapper svg.pierwsze-pietro-mapa-2 .cls-3 {
  fill: #fc0;
}
section.map-svg .map-svg-wrapper svg.pierwsze-pietro-mapa-2 .cls-4 {
  fill: #c7c6c6;
}
section.map-svg .map-svg-wrapper svg.pierwsze-pietro-mapa-2 .cls-5 {
  fill: #fff;
}
section.map-svg .map-svg-wrapper svg.pierwsze-pietro-mapa-2 .cls-6 {
  fill: #d8b135;
}
section.map-svg .map-svg-wrapper svg.pierwsze-pietro-mapa-2 .cls-7 {
  fill: #c5a547;
}
section.map-svg .map-svg-wrapper svg.pierwsze-pietro-mapa-2 .cls-8 {
  fill: #bda04e;
}
section.map-svg .map-svg-wrapper svg.pierwsze-pietro-mapa-2 .cls-9 {
  -webkit-clip-path: url(#clip-path-3);
  clip-path: url(#clip-path-3);
}
section.map-svg .map-svg-wrapper svg.pierwsze-pietro-mapa-2 .cls-10 {
  -webkit-clip-path: url(#clip-path-4);
  clip-path: url(#clip-path-4);
}
section.map-svg .map-svg-wrapper svg.pierwsze-pietro-mapa-2 .cls-11 {
  fill: #3c3c3b;
}
section.map-svg .map-svg-wrapper svg .area {
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
}
section.map-svg .map-svg-wrapper svg .area:hover {
  cursor: pointer;
  fill: #009ddb;
}
section.map-svg .map-svg-wrapper svg .area.filled {
  fill: #009ddb;
}
section.map-svg .pierwsze-pietro-row-1 .map-svg-wrapper,
section.map-svg .pierwsze-pietro-row-2 .map-svg-wrapper,
section.map-svg .parter-2-row .map-svg-wrapper {
  width: 850px !important;
  height: unset !important;
}
section.map-svg .pierwsze-pietro-row-1 .map-svg-wrapper svg,
section.map-svg .pierwsze-pietro-row-2 .map-svg-wrapper svg,
section.map-svg .parter-2-row .map-svg-wrapper svg {
  width: 100% !important;
  height: auto !important;
}
section.map-svg .parter-1-row .map-svg-wrapper {
  position: relative;
  height: 700px;
}
section.map-svg .parter-1-row .map-svg-wrapper svg {
  width: auto;
  height: 100%;
}
@media (max-width: 1650px) {
  section.map-svg .parter-1-row .map-svg-wrapper {
    height: 650px;
  }
}
@media (max-width: 1580px) {
  section.map-svg .parter-1-row .map-svg-wrapper {
    height: 550px;
  }
}
@media (max-width: 1500px) {
  section.map-svg .parter-1-row .map-svg-wrapper {
    height: 520px;
  }
}
@media (max-width: 1400px) {
  section.map-svg .parter-1-row .map-svg-wrapper {
    height: 700px;
  }
}
@media (max-width: 1400px) {
  section.map-svg .parter-1-row .map-svg-wrapper {
    height: 650px;
  }
}
section.map-svg .tooltip {
  background: white;
  border-radius: 0.875rem;
  padding: 1rem;
  width: 250px;
  position: absolute;
  opacity: 0;
  display: block;
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
}
section.map-svg .tooltip.visible {
  opacity: 1;
}
section.map-svg .tooltip:after {
  content: '';
  position: absolute;
  left: calc(50% - 0.75rem);
  bottom: -1rem;
  width: 1.5rem;
  height: 1.5rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/svg/tooltip.svg); */
}
section.map-svg .tooltip p {
  text-align: center;
}
section.map-svg .tooltip p:first-of-type {
  color: #009ddb;
  margin-bottom: 0.5rem;
}
section.map-svg #sala-zabaw-tooltip {
  left: 57% !important;
  top: -1rem !important;
}
section.map-svg #sala-sensoryczna-tooltip {
  left: 59% !important;
  top: 263px !important;
}

section.customer-dashboard {
  background-color: #eff2f3;
  position: relative;
  z-index: 10;
}
section.customer-dashboard:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -18rem;
  background-color: #eff2f3;
  width: 100%;
  height: 50rem;
  background-size: cover;
}
section.customer-dashboard .wrapper {
  background-color: white;
  border-radius: 1rem;
  padding: 2.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  height: 100%;
}
section.customer-dashboard .wrapper p {
  color: #42515a;
  font-size: 1.8rem;
  font-weight: 600;
  margin: 1rem 0 2rem 0;
}
section.customer-dashboard .points-wrapper {
  font-weight: 600;
}
section.customer-dashboard .points-wrapper h5 {
  color: #42515a;
  margin: 2rem 0;
}
section.customer-dashboard .points-wrapper div {
  width: 100%;
  background: #d4f2f2;
  border-radius: 1.4rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 1rem;
}
section.customer-dashboard .points-wrapper div h3 {
  color: #27bdbe;
}
@media (max-width: 992px) {
  section.customer-dashboard .col-lg-4 {
    margin-bottom: 2rem;
  }
}

.create-account-wrapper {
  background-color: white;
  border-radius: 1.4rem;
  width: 41rem;
  padding: 2.5rem;
  margin-bottom: 2rem;
}
.create-account-wrapper h5 {
  color: #42515a;
  font-family: 'Varela Round', sans-serif;
}
.create-account-wrapper p {
  font-weight: 600;
  font-size: 1.4rem;
  color: #8e979c;
  margin: 0.5rem 0 2rem 0;
}

section.login-section {
  background-color: #eff2f3;
  position: relative;
  z-index: 10;
}
section.login-section:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -50%;
  background-color: #eff2f3;
  width: 100%;
  height: 50%;
}
section.login-section:after {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  width: 50%;
  height: calc(100% + 19rem);
  background-size: cover;
  background-position-x: right;
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/img/podstrony/logowanie/zaloguj-sie.png); */
}
section.login-section .header-text {
  margin-top: 4rem;
}
section.login-section .header-text h3 {
  position: relative;
  z-index: 12;
  color: #f8f8f8;
  font-family: 'Varela Round', sans-serif;
}
section.login-section .login-box-wrapper h4 {
  color: #42515a;
  font-family: 'Varela Round', sans-serif;
  margin-bottom: 2rem;
}
section.login-section .login-box-wrapper form#loginform {
  background-color: white;
  border-radius: 1.4rem;
  width: 41rem;
  padding: 2.5rem;
  margin-bottom: 2rem;
}
section.login-section .login-box-wrapper form#loginform p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 1rem;
}
section.login-section .login-box-wrapper form#loginform p label {
  color: #42515a;
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}
section.login-section .login-box-wrapper form#loginform p.login-submit {
  margin-bottom: 0;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
section.login-section .login-box-wrapper form#loginform p.login-remember input {
  padding: 0 !important;
  margin-right: 0.5rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-width: 1.8rem;
  min-height: 1.8rem;
  width: 1.8rem;
  height: 1.8rem;
  border: 2px solid #d9dcde !important;
  border-radius: 5px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
section.login-section
  .login-box-wrapper
  form#loginform
  p.login-remember
  input:hover {
  cursor: pointer;
}
section.login-section
  .login-box-wrapper
  form#loginform
  p.login-remember
  input:before {
  content: '';
  width: 60%;
  height: 60%;
  border-radius: 2px;
  background-color: transparent;
}
section.login-section
  .login-box-wrapper
  form#loginform
  p.login-remember
  input:checked:before {
  background-color: #009ddb;
}
@media (max-width: 1200px) {
  section.login-section .col-xl-1 {
    display: none;
  }
  section.login-section .col-lg-6:last-of-type {
    padding-left: 3.5rem;
  }
  section.login-section .login-box-wrapper form#loginform {
    width: unset;
  }
  section.login-section .create-account-wrapper {
    width: unset;
  }
}
@media (max-width: 992px) {
  section.login-section .col-lg-6:last-of-type {
    padding-left: 1.5rem;
  }
}
@media (max-width: 768px) {
  section.login-section:after {
    display: none;
  }
  section.login-section .header-text {
    margin-top: 0;
    margin-bottom: 2rem;
  }
  section.login-section .header-text h3 {
    color: #42515a;
  }
}

section.register-section:after {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  width: 50%;
  height: calc(100% + 19rem);
  background-size: cover;
  background-position-x: right;
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/img/podstrony/logowanie/rejestracja.png); */
}
section.register-section form {
  background-color: white;
  border-radius: 1.4rem;
  width: 41rem;
  padding: 2.5rem;
  margin-bottom: 2rem;
}
section.register-section form p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 1rem;
}
section.register-section form p label {
  color: #42515a;
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}
section.register-section form p:last-of-type {
  margin-bottom: 0;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
section.register-section form div.woocommerce-privacy-policy-text {
  margin-bottom: 1rem;
}
section.register-section form div.woocommerce-privacy-policy-text p {
  display: inline-block;
  font-weight: 600;
  font-size: 14px;
  color: #8e979c;
}
section.register-section form div.woocommerce-privacy-policy-text p a {
  color: inherit;
}
@media (max-width: 1200px) {
  section.register-section form {
    width: unset;
  }
}

section.lost-password-section:after {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  width: 50%;
  height: calc(100% + 19rem);
  background-size: cover;
  background-position-x: right;
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/img/podstrony/logowanie/zapomniane-haslo.png); */
}
section.lost-password-section form {
  background-color: white;
  border-radius: 1.4rem;
  width: 41rem;
  padding: 2.5rem;
  margin-bottom: 2rem;
}
section.lost-password-section form p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 1rem;
}
section.lost-password-section form p label {
  color: #42515a;
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}
section.lost-password-section form p:last-of-type {
  margin-bottom: 0;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
section.lost-password-section form p.message {
  font-size: 1.2rem;
  color: #8e979c;
  font-weight: 600;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
section.lost-password-section form p.message svg {
  margin-right: 0.5rem;
}
@media (max-width: 1200px) {
  section.lost-password-section form {
    width: unset;
  }
}

section.error-page-section:after {
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/img/podstrony/logowanie/blad1.png); */
}
section.error-page-section .text-wrapper h2 {
  color: #42515a;
  font-family: 'Varela Round', sans-serif;
}
section.error-page-section .text-wrapper p {
  color: #8e979c;
  font-weight: 600;
  margin: 2rem 0;
}

section.home-main-section {
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/img/podstrony/home/main-bg1.png); */
  background-color: #f8f8f8;
  background-position-y: bottom;
  background-repeat: repeat-x;
  padding-top: 21rem;
  padding-bottom: 0;
  height: 100vh;
  min-height: 100vh;
  position: relative;
}
section.home-main-section:after {
  content: '';
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/img/podstrony/home/main.png); */
  width: 50%;
  height: 100%;
  top: 0;
  position: absolute;
  right: 0;
  background-repeat: no-repeat;
  background-position: left top;
  background-size: cover;
}
section.home-main-section .row,
section.home-main-section .col {
  height: 100%;
}
section.home-main-section .header-wrapper {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
section.home-main-section .header-wrapper h1 {
  color: #42515a;
}
section.home-main-section .header-wrapper .buttons-wrapper {
  margin-top: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
section.home-main-section .header-wrapper .buttons-wrapper a {
  margin-bottom: 1rem;
}
section.home-main-section .header-wrapper .buttons-wrapper a.border-btn {
  margin-right: 1rem;
  background-color: transparent;
}
section.home-main-section .container {
  position: relative;
  height: 100%;
  z-index: 3;
  padding-bottom: 8rem;
}
section.home-main-section .media-wrapper {
  border: 2px solid rgba(66, 81, 90, 0.2);
  border-radius: 3.8rem;
  position: absolute;
  padding: 1rem 1.5rem;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  bottom: 2rem;
}
section.home-main-section .media-wrapper a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
section.home-main-section .media-wrapper a:first-of-type {
  margin-right: 1.5rem;
}
section.home-main-section .next-section {
  display: inline-block;
  position: absolute;
  bottom: 2rem;
  left: calc((100% - 42px) / 2);
}
section.home-main-section .next-section a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-shadow: 0px 12px 10px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 12px 10px rgba(0, 0, 0, 0.05);
  background-color: white;
  width: 4.2rem;
  height: 4.2rem;
  border-radius: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
section.home-main-section .next-section a svg {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
@media (max-width: 992px) {
  section.home-main-section:after {
    /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/img/podstrony/home/main-mobile.png); */
    width: 100%;
    height: 60%;
    background-size: contain;
    background-position: right top;
  }
  section.home-main-section .header-wrapper {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  section.home-main-section .header-wrapper h1 {
    text-align: center;
  }
  section.home-main-section .header-wrapper .buttons-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  section.home-main-section .header-wrapper .buttons-wrapper a.border-btn {
    margin-right: 0;
  }
}
@media (max-width: 576px) {
  section.home-main-section {
    padding-top: 19rem;
    height: 95vh;
    min-height: 95vh;
  }
  section.home-main-section::after {
    height: 50%;
  }
}

body.home section.decorator-planner-section {
  padding: 12rem 0;
}
body.home section.decorator-planner-section a.turquoise-round-arrow {
  width: 9.7rem;
  height: 9.7rem;
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/svg/buttons/white-right-arrow.svg); */
  background-size: 30%;
}
body.home section.decorator-planner-section a.turquoise-round-arrow:hover {
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/svg/buttons/turquoise-right-arrow2.svg); */
}
@media (max-width: 576px) {
  body.home section.decorator-planner-section {
    padding: 4.8rem 0;
  }
  body.home section.decorator-planner-section a.turquoise-round-arrow {
    width: 8rem;
    height: 8rem;
  }
}

section.latest-news article .image-wrapper {
  position: relative;
  padding-top: 85%;
  overflow: hidden;
  border-radius: 1.4rem;
}
@media (max-width: 576px) {
  section.latest-news article .image-wrapper {
    padding-top: 61%;
  }
}
section.latest-news article .image-wrapper a {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
section.latest-news article .image-wrapper a img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
section.latest-news article .image-wrapper .mask {
  position: absolute;
  top: 5%;
  left: -10%;
  width: 120%;
  height: 110%;
}
section.latest-news article .text-wrapper {
  position: relative;
  padding: 2rem 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}
section.latest-news article .text-wrapper p.date {
  font-size: 1.2rem;
  color: #27bdbe;
}
section.latest-news article .text-wrapper h6 {
  color: #42515a;
  margin: 1.5rem 0;
}
section.latest-news article .text-wrapper h6 a {
  color: inherit;
  text-decoration: none;
}
section.latest-news article .text-wrapper p.excerpt {
  font-size: 1.4rem;
  color: #8e979c;
  margin-bottom: 2rem;
}
section.latest-news
  .row:last-of-type
  .col:nth-of-type(1)
  article
  .image-wrapper
  .mask {
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(7.36%, #27bdbe),
    color-stop(88.57%, rgba(39, 183, 190, 0))
  );
  background: -o-linear-gradient(
    bottom,
    #27bdbe 7.36%,
    rgba(39, 183, 190, 0) 88.57%
  );
  background: linear-gradient(
    360deg,
    #27bdbe 7.36%,
    rgba(39, 183, 190, 0) 88.57%
  );
  -webkit-transform: matrix(0.99, -0.08, 0.11, 1, 0, 0);
  -ms-transform: matrix(0.99, -0.08, 0.11, 1, 0, 0);
  transform: matrix(0.99, -0.08, 0.11, 1, 0, 0);
}
section.latest-news
  .row:last-of-type
  .col:nth-of-type(2)
  article
  .image-wrapper
  .mask {
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(7.36%, #ffcb04),
    color-stop(88.57%, rgba(255, 203, 4, 0))
  );
  background: -o-linear-gradient(
    bottom,
    #ffcb04 7.36%,
    rgba(255, 203, 4, 0) 88.57%
  );
  background: linear-gradient(
    360deg,
    #ffcb04 7.36%,
    rgba(255, 203, 4, 0) 88.57%
  );
  -webkit-transform: matrix(0.99, -0.08, 0.11, 1, 0, 0);
  -ms-transform: matrix(0.99, -0.08, 0.11, 1, 0, 0);
  transform: matrix(0.99, -0.08, 0.11, 1, 0, 0);
}
section.latest-news
  .row:last-of-type
  .col:nth-of-type(3)
  article
  .image-wrapper
  .mask {
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(7.36%, #f3a2a2),
    color-stop(88.57%, rgba(243, 162, 162, 0))
  );
  background: -o-linear-gradient(
    bottom,
    #f3a2a2 7.36%,
    rgba(243, 162, 162, 0) 88.57%
  );
  background: linear-gradient(
    360deg,
    #f3a2a2 7.36%,
    rgba(243, 162, 162, 0) 88.57%
  );
  -webkit-transform: matrix(0.98, -0.15, 0.2, 0.99, 0, 0);
  -ms-transform: matrix(0.98, -0.15, 0.2, 0.99, 0, 0);
  transform: matrix(0.98, -0.15, 0.2, 0.99, 0, 0);
}
section.latest-news a.all-news-btn {
  background-color: #fff5cd;
  border-radius: 1.4rem;
  padding: 2rem 3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-decoration: none;
  height: 100%;
  border: 2px solid transparent;
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
}
section.latest-news a.all-news-btn:after {
  content: '';
  width: 70%;
  height: 70%;
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/img/podstrony/home/news-btn-bg.png); */
  position: absolute;
  bottom: 0;
}
section.latest-news a.all-news-btn:hover {
  border-color: #ffcb04;
}
section.latest-news a.all-news-btn h6 {
  font-family: 'Varela Round', sans-serif;
  color: #42515a;
}
@media (max-width: 576px) {
  section.latest-news a.all-news-btn {
    height: 12rem;
  }
}

section.sightseeing {
  padding-top: 0;
}
section.sightseeing .wrapper {
  width: 90%;
  margin: auto;
  background-color: #27bdbe;
  border-radius: 1.4rem;
  padding: 5rem;
  font-weight: 600;
  height: 42rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/img/podstrony/home/zwiedzanie.png); */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
}
section.sightseeing .wrapper h4 {
  color: #f8f8f8;
  margin-bottom: 2rem;
}
section.sightseeing .wrapper h6 {
  color: #a9e5e5;
}

section.web-app {
  background-color: #eff2f3;
}
section.web-app:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -18rem;
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/img/podstrony-tlo-1.png); */
  width: 100%;
  height: 50rem;
  background-size: cover;
  background-color: #eff2f3;
}
section.web-app .image-wrapper img {
  max-width: 100%;
}
section.web-app .text-wrapper h4 {
  font-family: 'Varela Round', sans-serif;
  color: #42515a;
  margin-bottom: 4rem;
}
section.web-app .text-wrapper .wrapper {
  margin-bottom: 2rem;
  width: 80%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
section.web-app .text-wrapper .wrapper span {
  width: 4.4rem;
  min-width: 4.4rem;
  height: 4.4rem;
  border-radius: 100%;
  background-color: #ccebf8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: 2.5rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
}
section.web-app .text-wrapper .wrapper span:before {
  content: '';
  background-color: #009ddb;
  border: 4px solid white;
  border-radius: 100%;
  width: 2rem;
  height: 2rem;
}
section.web-app .text-wrapper .wrapper span:after {
  content: '';
  border: 2px dashed #b3b9bd;
  border-left: unset;
  position: absolute;
  height: 10rem;
  bottom: -10.5rem;
  left: 2rem;
}
section.web-app .text-wrapper .wrapper:nth-of-type(2) span {
  background: #f8d2d8;
}
section.web-app .text-wrapper .wrapper:nth-of-type(2) span::before {
  background-color: #da1f3c;
}
section.web-app .text-wrapper .wrapper:nth-of-type(3) span {
  background: #d4f2f2;
}
section.web-app .text-wrapper .wrapper:nth-of-type(3) span:after {
  display: none;
}
section.web-app .text-wrapper .wrapper:nth-of-type(3) span:before {
  background-color: #27bdbe;
}
section.web-app .text-wrapper .wrapper p {
  background-color: white;
  -webkit-box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.1);
  border-radius: 1.4rem;
  padding: 2rem;
  font-size: 1.2rem;
  font-weight: 600;
  color: #8e979c;
}
section.web-app .text-wrapper .buttons-wrapper {
  margin-top: 4rem;
}
section.web-app .text-wrapper .buttons-wrapper p {
  color: #42515a;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 2rem;
}
section.web-app .text-wrapper .buttons-wrapper a.border-btn {
  background-color: transparent;
  margin-right: 1.5rem;
}
@media (max-width: 1200px) {
  section.web-app .text-wrapper .wrapper {
    width: 100%;
  }
}
@media (max-width: 576px) {
  section.web-app {
    padding: 0;
  }
  section.web-app .text-wrapper h4 {
    text-align: center;
    margin-bottom: 2rem;
  }
  section.web-app .text-wrapper .wrapper span {
    display: none;
  }
}

.woocommerce-notices-wrapper div.woocommerce-message {
  border: 2px solid #27bdbe;
  background-color: white;
}
.woocommerce-notices-wrapper div.woocommerce-message a {
  color: inherit;
}
.woocommerce-notices-wrapper div.woocommerce-message:before {
  color: #27bdbe;
}
.woocommerce-notices-wrapper div.woocommerce-message a.button {
  border-radius: 0;
  color: white;
  background-color: #27bdbe;
  padding: 0.8rem 1.6rem !important;
  font-weight: 400;
  line-height: 1.25;
}
.woocommerce-notices-wrapper ul.woocommerce-error {
  border: 2px solid #b81c23;
  background-color: white;
}
.woocommerce-notices-wrapper ul.woocommerce-error a {
  color: inherit;
}

.woocommerce-message {
  border: 2px solid #27bdbe;
}
.woocommerce-message:before {
  color: #27bdbe;
}

.woocommerce-info {
  border: 2px solid #009ddb;
  background-color: white;
}
.woocommerce-info:before {
  color: #009ddb;
}
.woocommerce-info a {
  color: #42515a;
}
.woocommerce-info a.button {
  background: #009ddb !important;
  border-radius: 0 !important;
  color: white !important;
  font-weight: 400 !important;
}

.woocommerce-error {
  border: 2px solid #b81c23;
  background-color: white;
}

body.woocommerce-cart {
  background: #eff2f3;
}
body.woocommerce-cart .second-section {
  position: relative;
}
body.woocommerce-cart .second-section:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -18rem;
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/img/podstrony-tlo-1.png); */
  width: 100%;
  height: 50rem;
  background-size: cover;
  background-color: #eff2f3;
}

.cart-row .woocommerce-cart-form table.shop_table {
  border: none !important;
  border-collapse: separate;
  border-spacing: 0 5px;
}
.cart-row .woocommerce-cart-form table.shop_table thead th {
  font-weight: 600;
  text-transform: uppercase;
  color: #8e979c;
  font-size: 1.2rem;
  letter-spacing: 0.4px;
}
.cart-row .woocommerce-cart-form table.shop_table tbody {
  background-color: white;
}
.cart-row .woocommerce-cart-form table.shop_table tbody td {
  border: none !important;
}
.cart-row .woocommerce-cart-form table.shop_table tbody td:first-of-type {
  border-radius: 1rem 0 0 1rem;
}
.cart-row .woocommerce-cart-form table.shop_table tbody td:last-of-type {
  border-radius: 0 1rem 1rem 0;
}
.cart-row
  .woocommerce-cart-form
  table.shop_table
  tbody
  td.product-thumbnail
  img {
  width: 11rem;
  height: 10rem;
  border-radius: 1rem;
}
.cart-row .woocommerce-cart-form table.shop_table tbody td.product-name a {
  text-decoration: none;
}
.cart-row .woocommerce-cart-form table.shop_table tbody td.product-price {
  font-size: 2rem;
  color: #42515a;
  font-weight: 600;
}
.cart-row
  .woocommerce-cart-form
  table.shop_table
  tbody
  td.product-quantity
  input[type='number'] {
  padding: 1rem 0.5rem !important;
  width: 5rem;
}
.cart-row .woocommerce-cart-form table.shop_table tbody td.product-remove a {
  width: 3rem;
  height: 3rem;
  background-color: #27bdbe;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: white !important;
  font-size: 2.5rem;
  font-weight: 500;
}
.cart-row
  .woocommerce-cart-form
  table.shop_table
  tbody
  td.product-remove
  a:hover {
  background-color: #52cacb;
}
.cart-row .woocommerce-cart-form table.shop_table tbody tr:last-of-type td {
  border-radius: 1rem;
}
.cart-row
  .woocommerce-cart-form
  table.shop_table
  tbody
  tr:last-of-type
  button.pink-btn {
  min-width: 24rem;
}
.cart-row
  .woocommerce-cart-form
  table.shop_table
  tbody
  tr:last-of-type
  .actions-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .cart-row
    .woocommerce-cart-form
    table.shop_table
    tbody
    tr:last-of-type
    .actions-inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .cart-row
    .woocommerce-cart-form
    table.shop_table
    tbody
    tr:last-of-type
    .actions-inner
    button.blue-btn {
    width: unset;
  }
}
.cart-row
  .woocommerce-cart-form
  table.shop_table
  tbody
  tr:last-of-type
  .coupon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.cart-row
  .woocommerce-cart-form
  table.shop_table
  tbody
  tr:last-of-type
  .coupon
  input {
  min-width: 12rem;
  margin-right: 1rem;
}
@media (max-width: 420px) {
  .cart-row
    .woocommerce-cart-form
    table.shop_table
    tbody
    tr:last-of-type
    .coupon {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .cart-row
    .woocommerce-cart-form
    table.shop_table
    tbody
    tr:last-of-type
    .coupon
    input {
    margin-right: 0;
    margin-bottom: 0.7rem;
  }
}
.cart-row .cart-collaterals .cart_totals {
  width: 100%;
  background-color: white;
  border-radius: 1rem;
  padding: 2rem;
}
.cart-row .cart-collaterals .cart_totals a {
  margin-bottom: 0;
}
.cart-row .cart-collaterals .wc-proceed-to-checkout {
  padding-bottom: 0;
}
.cart-row .cart-collaterals table {
  border: none !important;
  font-size: 2rem;
}

.accesories-row {
  margin-top: 4rem;
}
.accesories-row .section-header-wrapper h3 {
  font-size: calc(25px + 23 * (100vw - 320px) / 1600);
  font-family: 'Varela Round', sans-serif;
  font-weight: 400;
}
.accesories-row .products-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.accesories-row .products-wrapper .simple-product {
  width: 25%;
  border: 2px solid white;
  padding: 2.5rem;
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
  height: 21rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.accesories-row .products-wrapper .simple-product h5 {
  color: #42515a;
  margin-bottom: 0;
}
.accesories-row .products-wrapper .simple-product .rendered-price-wrapper span {
  color: #8e979c;
}
.accesories-row .products-wrapper .simple-product:hover {
  background-color: #27bdbe;
  border-color: #27bdbe !important;
}
.accesories-row .products-wrapper .simple-product:hover h5 {
  color: #f8f8f8;
}
.accesories-row
  .products-wrapper
  .simple-product:hover
  .rendered-price-wrapper
  span {
  color: #d4f2f2;
}
.accesories-row .products-wrapper .simple-product:first-of-type {
  border-radius: 1.4rem 0 0 1.4rem;
  border-right: unset;
}
.accesories-row .products-wrapper .simple-product:nth-of-type(2) {
  border-right: unset;
}
.accesories-row .products-wrapper .simple-product:last-of-type {
  border-radius: 0 1.4rem 1.4rem 0;
  border-left: unset;
}
@media (max-width: 1200px) {
  .accesories-row .products-wrapper {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .accesories-row .products-wrapper .simple-product {
    width: 50%;
  }
  .accesories-row .products-wrapper .simple-product:first-of-type {
    border-radius: 1.4rem 0 0 0;
    border-bottom: unset;
  }
  .accesories-row .products-wrapper .simple-product:nth-of-type(2) {
    border-radius: 0 1.4rem 0 0;
    border-right: 2px solid white;
    border-bottom: unset;
  }
  .accesories-row .products-wrapper .simple-product:nth-of-type(3) {
    border-radius: 0 0 0 1.4rem;
    border-right: unset;
  }
  .accesories-row .products-wrapper .simple-product:last-of-type {
    border-radius: 0 0 1.4rem 0;
    border-left: 2px solid white;
  }
}
@media (max-width: 576px) {
  .accesories-row .products-wrapper .simple-product {
    width: 100%;
  }
  .accesories-row .products-wrapper .simple-product:first-of-type {
    border-radius: 1.4rem 1.4rem 0 0;
    border-right: 2px solid white;
  }
  .accesories-row .products-wrapper .simple-product:nth-of-type(2) {
    border-radius: 0 0 0 0;
  }
  .accesories-row .products-wrapper .simple-product:nth-of-type(3) {
    border-radius: 0 0 0 0;
    border-right: 2px solid white;
    border-bottom: unset;
  }
  .accesories-row .products-wrapper .simple-product:last-of-type {
    border-radius: 0 0 1.4rem 1.4rem;
    border-left: 2px solid white;
  }
}

body.woocommerce-checkout {
  background: #eff2f3;
}
body.woocommerce-checkout .second-section {
  position: relative;
}
body.woocommerce-checkout .second-section:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -18rem;
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/img/podstrony-tlo-1.png); */
  width: 100%;
  height: 50rem;
  background-size: cover;
  background-color: #eff2f3;
}

form.woocommerce-form-coupon {
  background-color: white;
  border-radius: 1.4rem;
  border: none;
}
form.woocommerce-form-coupon button {
  margin-left: 1rem !important;
}

form.checkout #customer_details {
  background-color: white;
  -webkit-box-shadow: 0px 12px 10px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 12px 10px rgba(0, 0, 0, 0.05);
  border-radius: 1.4rem;
  padding: 2rem;
  margin-bottom: 4rem;
}
form.checkout span.select2-selection {
  height: unset !important;
  padding: 1rem 1.4rem;
  border: 2px solid #d9dcde;
  color: #b8c2c8;
  border-radius: 1rem;
}
form.checkout span.select2-selection span.select2-selection__rendered {
  line-height: 1;
  color: inherit;
}
form.checkout span.select2-selection span.select2-selection__arrow {
  top: 6px;
  right: 6px;
}

span.select2-selection {
  height: unset !important;
  padding: 1rem 1.4rem;
  border: 2px solid #d9dcde !important;
  color: #b8c2c8;
  border-radius: 1rem !important;
}
span.select2-selection span.select2-selection__rendered {
  line-height: 1 !important;
  color: inherit !important;
}
span.select2-selection span.select2-selection__arrow {
  top: 6px !important;
  right: 6px !important;
}

#order_review #payment {
  background-color: white;
  -webkit-box-shadow: 0px 12px 10px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 12px 10px rgba(0, 0, 0, 0.05);
  border-radius: 1.4rem;
}
#order_review #payment li {
  list-style: none;
}
#order_review #payment button[name='woocommerce_checkout_place_order'] {
  font-size: 1.4rem !important;
  font-weight: 700 !important;
  padding: 1.2rem 2.2rem !important;
  border-radius: 1.4rem !important;
  font-family: 'Montserrat', sans-serif !important;
  text-decoration: none;
  border: none;
  text-transform: uppercase;
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  letter-spacing: 1.25px;
  line-height: 1.14;
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
  width: unset;
  color: white !important;
  background-color: #009ddb !important;
}
#order_review #payment button[name='woocommerce_checkout_place_order']:hover {
  background-color: #66c4e9 !important;
}

.thank-you-wrapper {
  background-color: white;
  padding: 4rem;
  border-radius: 1.4rem;
  -webkit-box-shadow: 0px 12px 10px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 12px 10px rgba(0, 0, 0, 0.05);
}
.thank-you-wrapper h4 {
  font-weight: 600;
}
.thank-you-wrapper h4 ~ p {
  font-weight: 600;
  color: #27bdbe;
}
.thank-you-wrapper .data-wrapper > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.thank-you-wrapper .data-wrapper .wrapper {
  width: 50%;
  padding: 1rem 0;
}
.thank-you-wrapper .data-wrapper .wrapper p {
  margin-bottom: 0;
  font-weight: 600;
  color: #42515a;
  font-size: 1.6rem;
}
.thank-you-wrapper .data-wrapper .wrapper p:first-of-type {
  color: #8e979c;
  font-size: 1.4rem;
  margin-bottom: 1rem;
}
.thank-you-wrapper section.woocommerce-customer-details {
  display: none;
}
.thank-you-wrapper section.woocommerce-order-details {
  padding: 0;
}
@media (max-width: 576px) {
  .thank-you-wrapper {
    padding: 1.5rem;
  }
}

body.woocommerce-account {
  background: #eff2f3;
}
body.woocommerce-account .second-section {
  position: relative;
}
body.woocommerce-account .second-section:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -18rem;
  /* background-image: url(../../../../../wp-content/themes/hullo-theme/assets/img/podstrony-tlo-1.png); */
  width: 100%;
  height: 50rem;
  background-size: cover;
  background-color: #eff2f3;
}

.woocommerce-MyAccount-content {
  background-color: white;
  border-radius: 1.4rem;
  padding: 2rem;
}

.woocommerce-MyAccount-navigation ul li {
  border-radius: 1.4rem;
  font-size: 1.8rem;
  color: #42515a;
  font-weight: 600;
  padding: 1rem 2rem;
  list-style: none;
}
.woocommerce-MyAccount-navigation
  ul
  li.woocommerce-MyAccount-navigation-link--downloads {
  display: none;
}
.woocommerce-MyAccount-navigation
  ul
  li.woocommerce-MyAccount-navigation-link--customer-logout {
  display: none;
}
.woocommerce-MyAccount-navigation ul li.is-active {
  background-color: white;
}
.woocommerce-MyAccount-navigation ul li a {
  text-decoration: none;
}
@media (max-width: 576px) {
  .woocommerce-MyAccount-navigation ul li {
    font-size: 1.4rem;
    padding: 1rem;
    margin-left: 0;
  }
}

table.account-orders-table {
  border: none !important;
  border-collapse: separate;
  border-spacing: 0 5px;
}
table.account-orders-table a.view {
  font-size: 1.2rem !important;
  font-weight: 700 !important;
  padding: 0.7rem 1.4rem !important;
  border-radius: 0.7rem !important;
  font-family: 'Montserrat', sans-serif !important;
  text-decoration: none !important;
  border: none;
  text-transform: uppercase;
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  letter-spacing: 1.25px;
  line-height: 1.14;
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
  color: white !important;
  background-color: #009ddb !important;
}
table.account-orders-table a.view:hover {
  background-color: #66c4e9 !important;
}
table.account-orders-table th {
  padding: 8px 16px !important;
  background-color: #f8f8f8;
}
table.account-orders-table th:first-of-type {
  border-radius: 1rem 0 0 1rem;
}
table.account-orders-table th:last-of-type {
  border-radius: 0 1rem 1rem 0;
}
table.account-orders-table tbody tr {
  background-color: #f8f8f8;
}
table.account-orders-table tbody tr td:first-of-type {
  border-radius: 1rem 0 0 1rem;
}
table.account-orders-table tbody tr td:last-of-type {
  border-radius: 0 1rem 1rem 0;
}
table.account-orders-table td {
  border: none !important;
  padding: 8px 16px !important;
}

mark {
  background-color: #27bdbe;
}

section.woocommerce-order-details {
  padding: 2rem 0;
}

section.woocommerce-customer-details {
  padding: 0;
}

.u-columns.woocommerce-Addresses .col-1,
.u-columns.woocommerce-Addresses .col-2 {
  width: 50%;
  max-width: 50%;
  padding: 1rem 1rem 1rem 0;
}
.u-columns.woocommerce-Addresses .col-1 header,
.u-columns.woocommerce-Addresses .col-2 header {
  position: relative;
  margin-bottom: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.u-columns.woocommerce-Addresses .col-1 header h3,
.u-columns.woocommerce-Addresses .col-2 header h3 {
  margin-right: 2rem;
  margin-bottom: 0;
}
.u-columns.woocommerce-Addresses .col-1 header a,
.u-columns.woocommerce-Addresses .col-2 header a {
  font-size: 1.4rem !important;
  font-weight: 700 !important;
  padding: 1.2rem 2.2rem !important;
  border-radius: 1.4rem !important;
  font-family: 'Montserrat', sans-serif !important;
  text-decoration: none !important;
  border: none;
  text-transform: uppercase;
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  letter-spacing: 1.25px;
  line-height: 1.14;
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
  color: white !important;
  background-color: #009ddb !important;
}
.u-columns.woocommerce-Addresses .col-1 header a:hover,
.u-columns.woocommerce-Addresses .col-2 header a:hover {
  background-color: #66c4e9 !important;
}
@media (max-width: 1200px) {
  .u-columns.woocommerce-Addresses .col-1,
  .u-columns.woocommerce-Addresses .col-2 {
    width: 100%;
    max-width: 100%;
  }
}

button[name='save_account_details'],
button[name='save_address'] {
  font-size: 1.4rem !important;
  font-weight: 700 !important;
  padding: 1.2rem 2.2rem !important;
  border-radius: 1.4rem !important;
  font-family: 'Montserrat', sans-serif !important;
  text-decoration: none !important;
  border: none;
  text-transform: uppercase;
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  letter-spacing: 1.25px;
  line-height: 1.14;
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
  color: white !important;
  background-color: #009ddb !important;
}
button[name='save_account_details']:hover,
button[name='save_address']:hover {
  background-color: #66c4e9 !important;
}

#hc {
  background: black;
}
#hc * {
  color: white;
  text-shadow: none;
  stroke: white;
  -webkit-animation: none !important;
  animation: none !important;
}
#hc a {
  background-color: black;
  color: white;
  text-decoration: underline;
}
#hc a:focus {
  color: yellow;
  text-decoration: none;
}
#hc a:hover,
#hc a:hover * {
  color: yellow;
  text-decoration: none;
}
#hc table {
  border-collapse: collapse;
  border: 1px solid white;
}
#hc table th,
#hc table td {
  border-right: 1px solid white;
  border-bottom: 1px solid white;
}
#hc table td:last-child {
  padding-right: 28px;
}
#hc a:focus {
  z-index: 5;
}
#hc .pink-btn,
#hc .blue-btn,
#hc .btn,
#hc .cart-btn,
#hc .account-btn {
  background-color: black !important;
  border: 2px solid white !important;
  color: white !important;
}
#hc .pink-btn:hover,
#hc .blue-btn:hover,
#hc .btn:hover,
#hc .cart-btn:hover,
#hc .account-btn:hover {
  color: yellow !important;
}
#hc section {
  background-color: black;
}
#hc header .info-row .opening-hours .hidden-wrapper {
  background-color: black;
}
#hc header .info-row .opening-hours .hidden-wrapper:before {
  background-color: black;
}
#hc header .top-row {
  background-color: black;
}
#hc header .top-row .hidden-account-buttons {
  background-color: black;
}
#hc header .top-row .hidden-account-buttons:before {
  background-color: black;
}
#hc header div.nav {
  border: 2px solid white;
  border-radius: 1.4rem;
}
#hc header .bottom-row {
  background-color: black;
}
#hc header .bottom-row ul.nav > li a:after {
  display: none !important;
}
#hc header .bottom-row ul.nav > li.menu-item-has-children ul.sub-menu {
  background-color: black;
}
#hc
  header
  .bottom-row
  ul.nav
  > li.menu-item-has-children
  ul.sub-menu
  li
  a:hover {
  border-color: yellow;
}
#hc section.home-main-section {
  background-image: none;
}
#hc section.home-main-section .media-wrapper {
  border-color: white;
}
#hc .available-attractions .all-attractions-wrapper {
  background-color: black;
}
#hc .available-attractions .all-attractions-wrapper:before {
  display: none;
}
#hc section.offer-section .tabs-wrapper .nav {
  border: 2px solid white;
  background-color: black;
}
#hc section.offer-section .tabs-wrapper .nav button {
  border: 2px solid white !important;
  background-color: black;
}
#hc section.offer-section .tabs-wrapper .nav button:hover {
  color: yellow !important;
}
#hc section.web-app {
  background-color: black;
}
#hc section.web-app:before {
  display: none;
}
#hc section.web-app .text-wrapper .wrapper p {
  background-color: black;
}
#hc section.subpage-header-section {
  background-image: none;
}
#hc section.gallery-section:before,
#hc section.other-news:before,
#hc section.amelia-section:before,
#hc section.faq-section:before,
#hc section.second-section:before {
  display: none;
}
#hc section.news-section .articles-wrapper article .text-wrapper {
  background-color: black;
}
#hc section.single-post-section .info-box .text-wrapper {
  background-color: black;
}
#hc section.single-post-section .info-box .text-wrapper .share-wrapper div {
  background-color: black;
  border: 2px solid white;
}
#hc .description-slider-wrapper {
  background-color: black;
}
#hc section.other-attractions-section .box-wrapper .left-box {
  background-color: black;
  border: 2px solid white;
}
#hc section.other-attractions-section .contact-wrapper {
  background-color: black;
  border: 2px solid white;
}
#hc section.app-section {
  background-color: black !important;
}
#hc section.app-section .row:nth-of-type(2) {
  background: black;
}
#hc section.app-section .tabs-wrapper .nav {
  background-color: black;
  border: 2px solid white;
}
#hc section.app-section .tabs-wrapper .nav button {
  background-color: black;
  border: 2px solid white;
}
#hc .accordion-wrapper {
  background-color: black;
}
#hc .accordion-wrapper .panel,
#hc .accordion-wrapper button {
  background-color: black;
}
#hc section.questions-form-section .container:first-of-type {
  background-color: black;
}
#hc section.questions-form-section .container:first-of-type .text-wrapper {
  background-color: black;
  border: 1px solid white;
}
#hc section.questions-form-section .container:first-of-type:after {
  display: none;
}
#hc section.questions-form-section .container:last-of-type:before {
  background-color: black;
}
#hc section.questions-form-section .container:last-of-type .form-wrapper {
  background-color: black;
}
#hc .woocommerce-info {
  background-color: black;
}
#hc form.woocommerce-cart-form table thead tr {
  border: 1px solid white;
}
#hc form.woocommerce-cart-form table tbody {
  background-color: black;
}
#hc .cart-row .cart-collaterals .cart_totals {
  background-color: black;
}
#hc .cart-row .cart-collaterals .cart_totals tbody {
  border: 1px solid white;
}
#hc form.checkout #customer_details {
  background-color: black;
}
#hc span.select2-selection {
  background-color: black;
}
#hc #payment {
  background-color: black;
  border: 2px solid white;
}
#hc #order_review #payment button[name='woocommerce_checkout_place_order'] {
  border: 2px solid white !important;
  background-color: black !important;
  color: white;
  text-decoration: underline;
}
#hc
  #order_review
  #payment
  button[name='woocommerce_checkout_place_order']:hover {
  color: yellow !important;
  background-color: black !important;
}
#hc .thank-you-wrapper {
  background-color: black;
  border: 2px solid white;
}
#hc .woocommerce-MyAccount-navigation ul li {
  background-color: black;
  border: 2px solid white;
  margin-bottom: 1rem;
}
#hc .woocommerce-MyAccount-content {
  background-color: black;
}
#hc textarea,
#hc input {
  background-color: black;
}
#hc .sponsors .wrapper {
  background-color: black;
}
#hc footer {
  background-color: black;
}
#hc .upper-footer {
  background-color: black;
}
#hc .upper-footer .newsletter-wrapper {
  background-color: black;
}
#hc .upper-footer .newsletter-wrapper input[type='submit'].btn {
  color: transparent !important;
}
#hc .hamburger-inner,
#hc .hamburger-inner::before,
#hc .hamburger-inner::after,
#hc .logo-wrapper .logo-uw:before {
  background-color: #fff !important;
}
#hc .hamburger,
#hc .search-form button {
  background: #000;
}

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  -webkit-transition-property: opacity, -webkit-filter;
  transition-property: opacity, -webkit-filter;
  -o-transition-property: opacity, filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  -webkit-transition-duration: 0.15s;
  -o-transition-duration: 0.15s;
  transition-duration: 0.15s;
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}
.hamburger:hover {
  opacity: 0.7;
}
.hamburger.is-active:hover {
  opacity: 0.7;
}
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #42515a;
}

.hamburger-box {
  width: 30px;
  height: 18px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1px;
}
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 30px;
  height: 2px;
  background-color: #42515a;
  border-radius: 0;
  position: absolute;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.15s;
  -o-transition-duration: 0.15s;
  transition-duration: 0.15s;
  -webkit-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
}
.hamburger-inner::before,
.hamburger-inner::after {
  content: '';
  display: block;
}
.hamburger-inner::before {
  top: -8px;
}
.hamburger-inner::after {
  bottom: -8px;
}

/*
 * Slider
 */
.hamburger--slider .hamburger-inner {
  top: 1px;
}
.hamburger--slider .hamburger-inner::before {
  top: 8px;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  -o-transition-property: transform, opacity;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-duration: 0.15s;
  -o-transition-duration: 0.15s;
  transition-duration: 0.15s;
}
.hamburger--slider .hamburger-inner::after {
  top: 16px;
}
.hamburger--slider.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, 8px, 0) rotate(45deg);
  transform: translate3d(0, 8px, 0) rotate(45deg);
}
.hamburger--slider.is-active .hamburger-inner::before {
  -webkit-transform: rotate(-45deg) translate3d(-4.2857142857px, -6px, 0);
  transform: rotate(-45deg) translate3d(-4.2857142857px, -6px, 0);
  opacity: 0;
}
.hamburger--slider.is-active .hamburger-inner::after {
  -webkit-transform: translate3d(0, -16px, 0) rotate(-90deg);
  transform: translate3d(0, -16px, 0) rotate(-90deg);
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {
  content: '';
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir='rtl'] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  /* background: #fff url('./ajax-loader.gif') center center no-repeat; */
}

/* Icons */
@font-face {
  font-family: 'slick';
  /* src: url('./fonts/slick.eot');
  src: url('./fonts/slick.eot?#iefix') format('embedded-opentype'),
    url('./fonts/slick.woff') format('woff'),
    url('./fonts/slick.ttf') format('truetype'),
    url('./fonts/slick.svg#slick') format('svg'); */
  font-weight: normal;
  font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir='rtl'] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: '←';
}
[dir='rtl'] .slick-prev:before {
  content: '→';
}

.slick-next {
  right: -25px;
}
[dir='rtl'] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: '→';
}
[dir='rtl'] .slick-next:before {
  content: '←';
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: '•';
  width: 20px;
  height: 20px;
  font-family: 'slick';
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}
